export const p35erxingzuxuanhouerfushi =  {
	balls:[
	    [-1,-1,-1,-1,-1,-1,-1,-1,-1,-1]
	],
    num:[
	    [0,1,2,3,4,5,6,7,8,9]
    ],
    views:[
	    [0,1,2,3,4,5,6,7,8,9]
    ],
    titles: [
        '组选'
    ],
    chooseType:['全','大','小','奇','偶','清'],
    getLottery:(num,balls)=>{
        let str = ""; 
        for(let i = 0;i < balls.length;i++){
		    for(let l = 0;l < balls[i].length;l++){
			    if(balls[i][l] > 0){
				    str +=num[i][l];
				}
		    }
    	}
	    return str;
    },
    getNum:(balls)=>{
        let num = 0,len_all=0;
        for(let i=0,len=balls.length;i<len;i++){
            for(let j=0,len_j=balls[i].length;j<len_j;j++){
                if(balls[i][j]>0){
                   len_all++;
                }
            }
        }
        if(len_all>=2){
            num = len_all*(len_all-1)/2;
        }
        return num;
    },
    getView: (views,balls)=>{
        let str = ""; 
        for(let i = 0;i < balls.length;i++){
            for(let l = 0;l < balls[i].length;l++){
                if(balls[i][l] > 0){
                    str +=views[i][l];
                }
            }
        }
        return str;
    }
}
