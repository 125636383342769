import {useQuery} from "../../hooks";
import {useEffect, useMemo, useState} from "react";
import {Select} from "../../components/select/select";
import {Loading} from "../../components/loading";
import {Tabs} from "../../components/tabs";
import {Table} from "../../components/table/table";
import './f.scss'

export const Fee = () => {
    const [getFees, fLd, fDa] = useQuery('fees');
    const [v, vv] = useState(0)
    const [t, tt] = useState(0)
    const cate = ['LIVE', 'SPORT', 'LOTTO', 'RNG', 'PVP', 'FISH']
    const ns = ['真人', '体育/电竞', '彩票', '电子', '棋牌', '捕鱼']
    const ms = useMemo(() => {
        const n = new Date()
        const y = n.getFullYear()
        const z = n.getMonth()
        const s = []
        const fm = d => [d.getFullYear() + (100 + d.getMonth() + 1 + '').substring(1), d.getFullYear() + '年' + (d.getMonth() + 1) + '月']
        let m = 3
        while (m--) {
            s[m] = (fm(new Date(y, z - m)))
        }
        return s
    }, [])
    const data = useMemo(() => {
        if (!fDa) return []
        const k = cate[t]
        return fDa.filter(a => a['game_category'] === k.replace('ELECT', 'SPORT'))
    }, [fDa, t])
    useEffect(() => {
        const m = ((ms && ms[v]) || [])[0]
        if (m) getFees(m)
    }, [v, ms])
    return <div className={'feePage'}>
        <div className={'r'}>
            <Tabs act={t} change={tt}>{o => <div className={'tabs'}>{
                cate.map((n, i) => <div {...o(i)}>{ns[i]}</div>)
            }</div>}</Tabs>
            <label className={'sel'}>
                <span>月份：</span>
                <Select
                    valueRender={() => (ms[v] || [])[1]}
                    value={v} onChange={vv} items={ms.map((a, i) => [i, ...a])}/>
            </label>
        </div>
        <div>
            <Table
                className={'x-table'}
                row={[
                    {name: '厂商', key: 'product'},
                    {cell:a=>`${a.rate}%`, name: '厂商费率'},
                    {key: 'profit', name: '游戏盈亏'},
                    {key: 'fee', name: '厂商费'},
                ]}
                data={data}
            />
        </div>
        <Loading act={fLd}/>
    </div>
}