export const sscerxingzhixuanhouerhezhi = {
	balls:[
	    [-1,-1,-1,-1,-1,-1,-1,-1,-1,-1,-1,-1,-1,-1,-1,-1,-1,-1,-1]
	],
    num:[
	    [0,1,2,3,4,5,6,7,8,9,10,11,12,13,14,15,16,17,18]
    ],
    views:[
	    [0,1,2,3,4,5,6,7,8,9,10,11,12,13,14,15,16,17,18]
    ],
    titles: [
        '后二和值'
    ],
    getLottery:(num,balls)=>{
        let str = '';
        for(let i = 0,len_i = balls.length;i < len_i;i++){
		    for(let j = 0,len_j = balls[i].length;j < len_j;j++){
			    if(balls[i][j] > 0){
                   str += str?'|':'';
                   str += num[i][j];
				}
		    }
    	}
	    return str;
    },
    chooseType:['全','大','小','奇','偶','清'],
    getNum:(balls)=>{
        let num = 0,bets = [1,2,3,4,5,6,7,8,9,10];
        for(let i=0,len=balls.length;i<len;i++){
            for(let j=0,len_j = balls[i].length;j<len_j;j++){
                if(balls[i][j]>-1){
                    num += j>9?bets[18-j]:bets[j];
                }
            }
        }
        return num;
    },
    getView: (views,balls)=>{
        let str = ''; 
        for(let i = 0,len_i = balls.length;i < len_i;i++){
		    for(let j = 0,len_j = balls[i].length;j < len_j;j++){
			    if(balls[i][j] > 0){
                    str += str?'|':'';
                    str += views[i][j];
				}
		    }
    	}
	    return str;
    }
}
