import React, {useEffect, useMemo, useState} from 'react';
import {observable, observe} from "mobx"
import {observer} from "mobx-react-lite"
import {createBrowserHistory} from "history";
import {decrypt, encrypt, encStoreKey, tip, WS} from '../utils';
import {authRoutes} from "../routes/routes";

export const history = createBrowserHistory();
history.back = () => {
    if (history.length) history.goBack()
    else history.replace('/')
}
window.lastPage = '/';
window.lastPage1 = '/';
export const useAuth = p => {
    const {globalStore} = stores;
    const [u, uu] = useState(0)
    const {token, user_level, is_agent} = globalStore;
    // const {token, user_level, is_agent} = globalStore;
    useEffect(() => {
        observe(globalStore, ({name}) => {
            if (/token|user_level|is_agent/g.test(name)) uu(Math.random())
            // if (/token|user_level|is_agent/g.test(name)) uu(Math.random())
        })
    }, [])
    const [nTk, uLv] = authRoutes.find(([a]) => a === p) || [];
    if (!nTk) window.lastPage = history.location;
    if (token) window.hasToken = 1;
    return useMemo(() => [
        !!token || !nTk,
        (nTk && !window.hasToken) ? () => {
            window.hasToken = 0;
            tip && tip.popLogin && tip.popLogin(1)
        } : () => {
            window.hasToken = 0;
        },
        user_level === uLv || (/agent/.test(p) && !is_agent)
    ], [nTk, token, is_agent, u])
}
const stores = {};
Object.defineProperty(stores, 'query', {
    get() {
        return stores._query
    }
})
const safeAssign = (a, b = {}) => {
    Object.keys(b).forEach(k => {
        try {
            a[k] = b[k]
        } catch (e) {
        }
    })
}

/**
 * 加载并包装对象到stores
 * @param {Object} o
 */
const loadStore = o => {
    Object.keys(o).forEach(
        k => {
            const _k = encStoreKey('_' + k);
            const s = o[k];
            const ini = JSON.parse(JSON.stringify(s));
            const f = {}
            const x = {
                __change: 0,
                clearStore() {
                    const o = this.___;
                    const d = {};
                    if (o) {
                        o.forEach(k => {
                            d[k] = this[k]&&JSON.parse(JSON.stringify(this[k]));
                        });
                    }
                    safeAssign(this, {...JSON.parse(JSON.stringify(ini)), ...d});
                },
                getStore: name => name ? stores[name] : stores,
                sync() {
                    const v = sessionStorage.getItem(_k);
                    if (v !== 'undefined' && v !== null) {
                        safeAssign(this, JSON.parse(decrypt(v)))
                    }
                }
            }
            const h = {}
            Object.keys(s).forEach(k => {
                const v = s[k];
                const d = Object.getOwnPropertyDescriptor(s, k)
                if (d.get || d.set) {
                    h[k] = d
                } else if ('function' === typeof v) {
                    f[k] = v;
                } else {
                    if (Array.isArray(o)) {
                        try {
                            x[k] = observable.array(o);
                        } catch (e) {
                        }
                    } else {
                        x[k] = v
                    }
                }
            })
            const ss = observable(x);
            Object.keys(h).forEach(k => {
                Object.defineProperty(ss, k, h[k])
            });
            Object.keys(f).forEach(k => {
                Object.defineProperty(ss, k, {
                    configurable: true,
                    enumerable: true,
                    set(v) {
                        f[k] = v;
                        ss.__change++
                    },
                    get() {
                        return f[k]
                    }
                })
            })
            observe(ss, (a) => {
                if (a.name === '__change') return;
                const d = {...ss};
                Object.keys(d).forEach(k => {
                    if ('_' === k[0]) {
                        delete d[k]
                    }
                });
                sessionStorage.setItem(_k, encrypt(JSON.stringify(d)));
            })
            const st = stores[k] = ss;
            st.sync();
        }
    );
}

loadStore(require('./global'));
loadStore(require('./menu'));
loadStore(require('./bet'));
loadStore(require('./trend'));
loadStore(require('./channel'));
loadStore(require('./bank'));
loadStore(require('./area'));
loadStore(require('./prize'));
loadStore(require('./transChangeType'));
loadStore(require('./third'));

stores.clear = () => {
    Object.values(stores).forEach(o => o.clearStore && o.clearStore())
}

// loadStore(require('./bankCards'));

export {stores}

export function widthStore(fn) {
    return React.memo(
        observer(props => fn(stores, props))
    );
}

export const logout = () => {
    WS.close();
    const {
        globalStore, channelStore, myBankStore, bankCards, betListStore
    } = stores;
    globalStore && globalStore.clearStore();
    myBankStore && myBankStore.clearStore();
    channelStore && channelStore.clearStore();
    bankCards && bankCards.clearStore();
    betListStore && betListStore.clearStore();

    (localStorage.getItem('enc_k') || '').split(',').forEach(a => {
        if (a && a.length > 2) {
            const s = [sessionStorage, localStorage][a[0]];
            if (s) s.removeItem(a.substr(1))
        }
    })
}

