import React, {useState, useEffect} from 'react';
import {tip} from "../../utils";
import {Portals} from "../portals";
import './notice.scss';
import {widthStore} from "../../store";

/**
 * 右下角弹窗通知
 * @param  {Object} cfg   {key,render,data,wait}
 * @param close
 * @returns {*}
 * @constructor
 */
const N = ({cfg = {}, close}) => {
    const [s, ss] = useState(0);
    const {title, data, onClose} = cfg;
    useEffect(() => {
        const timer = setTimeout(() => ss(1), 100);
        const timer1 = setTimeout(() => {
            if (onClose) onClose();
            close(ss)
        }, 9e3);
        return () => {
            clearTimeout(timer);
            clearTimeout(timer1);
        }
    }, []);
    return <div className={'win-notice' + (s ? ' act' : '')}>
        <div className={'t'}>{title}<span onClick={() => {
            if (onClose) onClose();
            close(ss)
        }}>X</span></div>
        <div className={'c'}>
            {data}
        </div>
    </div>
};

const memNoticeList = [];
export const NoticeList = widthStore(({globalStore}) => {
    const [noticeLis, setNotice] = useState([]);
    useEffect(() => {
        if(!globalStore.token){
            memNoticeList.length = 0;
            setNotice([]);
        }
        return ()=>{
            if(!globalStore.token){
                memNoticeList.length = 0;
                setNotice([]);
            }
        }
    }, [globalStore.token]);
    useEffect(() => {
        /**
         * @function tip.popup
         * @param {number} a
         */
        tip.popup = (a) => {
            if (!a.key) a.key = Math.random() + Date.now();
            if (memNoticeList.find(({key}) => key === a.key)) return;
            memNoticeList.push(a);
            setNotice(memNoticeList.concat())
        }
    }, []);
    return <Portals>
        <div className={'notice-list a'}>
            {noticeLis.map(c => {
                const close = (ss) => {
                    ss(0);
                    setTimeout(() => {
                        const i = memNoticeList.findIndex(({key}) => key === c.key);
                        if (i > -1) {
                            memNoticeList.splice(i, 1);
                            setNotice(memNoticeList.concat());
                        }
                    }, 400);
                };
                return <N cfg={c} close={close} key={c.key}/>
            })}
        </div>
    </Portals>
});
