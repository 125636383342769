export const pk10zhixuanhezhihezhiguanyahezhi = {
	balls:[
	    [-1,-1,-1,-1,-1,-1,-1,-1,-1,-1,-1,-1,-1,-1,-1,-1,-1]
	],
    num:[
	    [3,4,5,6,7,8,9,10,11,12,13,14,15,16,17,18,19]
    ],
    views:[
	    [3,4,5,6,7,8,9,10,11,12,13,14,15,16,17,18,19]
    ],
    titles: [
	    '冠亚和'
    ],
    getLottery:(num,balls)=>{
        let txt = '';
        for(let i = 0;i < balls.length;i++){
		    for(let l = 0;l < balls[i].length;l++){
			    if(i == 0){
				    if(balls[i][l] > 0){
					    txt+=num[i][l]+"|"
				    }
			    }
		    }
	    }
	    return  txt.substring(0,txt.length-1);
    },
    getNum:(balls)=>{
        let arr = balls,num = 0;
		for(let i=0,len=arr.length;i<len;i++){
            for(let j=0,len_j=arr[i].length;j < len_j; j++){
			    if(arr[i][j]> 0){
				    num++;
			    }
		    }                    
        }  
        return num;
    },
    getView: (views,balls)=>{
        let txt = '',arr = [];
        for(let i = 0;i < balls.length;i++){
		    for(let l = 0;l < balls[i].length;l++){
			    if(i == 0){
				    if(balls[i][l] > 0){
					    txt+=views[i][l]+"|"
				    }
			    }
		    }
	    }
	    return  txt.substring(0,txt.length-1);
    }
}
