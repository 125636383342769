import React, {useEffect, useState} from 'react';
import {widthDashBoard} from "../../components/dashboard/Dashboard";
import {useQuery} from "../../hooks";
import {Table} from "../../components/table/table";
import {Loading} from "../../components/loading";
import {Paging} from "../../components/paging/Paging";
import {DashTitle} from "../common/com";

export  const TrcD = ({d,ls,onChange})=>{
    const [query, loading, res] = useQuery('getTraceLs');
    const [stopDetail, stopDetailLoading] = useQuery('stopTraceDetail');
    const [stopTrace, sTraceLoading] = useQuery('stopTrace');
    const {
        coefficient, display_bet_number, finished_amount, prize, id,
        lottery_id, bought_at, start_issue, status, prize_group, canceled_amount,
        serial_number, title, total_issues, commission, amount, stop_on_won
    } = d;
    useEffect(() => {
        if(d)query({trace_id: id})
    }, []);
    if(!d)return  null;
    const tableConfig = [
        {
            name: '奖期',
            key: 'issue'
        },
        {
            name: '追号倍数',
            key: 'multiple'

        },
        {
            name: '投注金额',
            key: 'amount'
        },
        {
            name: '追号状态',
            cell({status}) {
                return ['进行中', '已投注', '用户终止', '管理员终止', '系统终止'][status]
            }
        },
        {
            name: '中奖',
            cell: ({prize, project_status}) => {
                return project_status === 3 ? parseFloat(prize).toFixed(3)
                    : ['待开奖', '已撤销', '未中奖', '已中奖', '已派奖', '系统撤销'][project_status]
            }
        },
        {
            name: '操作',
            cell({id:detailId, status}) {
                return status < 1 ? <div className={'cc'} onClick={() => stopDetail({id,detail_id: detailId}, () => {
                    query({trace_id: id})
                })}>
                    取消当前期
                </div> : '--'
            }
        },
    ];
    const handleBtnClick = (p) => {
        query({
            trace_id: id,
            page: p
        })
    };
    const {last_page: totalPage, stepPage = 3, current_page: cPage} = res || {};
    const name = (ls.find(({id}) => id === lottery_id) || {}).name;
    return <div className={'trc-d'}>
        <label className={'lt'}>投注详情</label>
        <div className={'bx'}>
            <div className={'r'}>
                <div className={'l'}>
                    <label>游戏</label>
                    <span>{name}</span>
                </div>
                <div>
                    <label>追号编号</label>
                    <span>{serial_number}</span>
                </div>
            </div>
            <div className={'r'}>
                <div className={'l'}>
                    <label>玩法</label>
                    <span>{title}</span>
                </div>
                <div>
                    <label>追号时间</label>
                    <span>{bought_at}</span>
                </div>
            </div>
            <div className={'r'}>
                <div className={'l'}>
                    <label>开始期号</label>
                    <span>{start_issue}</span>
                </div>
                <div>
                    <label>追号期数</label>
                    <span>{total_issues}</span>
                </div>
            </div>
            <div className={'r'}>
                <div className={'l'}>
                    <label>追号金额</label>
                    <span>{amount}</span>
                </div>
                <div>
                    <label>完成金额</label>
                    <span>{finished_amount}</span>
                </div>
            </div>
            <div className={'r'}>
                <div className={'l'}>
                    <label>取消金额</label>
                    <span>{canceled_amount}</span>
                </div>
                <div>
                    <label>中奖金额</label>
                    <span>{prize}</span>
                </div>
            </div>
            <div className={'r'}>
                <div className={'l'}>
                    <label>追号状态</label>
                    <span>{['进行中', '已完成', '用户终止', '管理员终止', '系统终止'][status]}</span>
                </div>
                <div>
                    <label>模式</label>
                    <span>{['元', '角', '分'][Math.log10(1 / coefficient)]}</span>
                </div>
            </div>
            <div className={'r'}>
                <div className={'l'}>
                    <label>追号奖金组</label>
                    <span>{prize_group}</span>
                </div>
                <div>
                    <label>中奖后停止任务</label>
                    <span>{['否', '是'][stop_on_won]}</span>
                </div>
            </div>
            <div className={'r'}>
                <div className={'l'}>
                    <label>模式</label>
                    <span>{['元', '角', '分'][Math.log10(1 / coefficient)]}</span>
                </div>
                <div>
                    <label>投注金额</label>
                    <span>{amount}</span>
                </div>
            </div>
            <div className={'r'}>
                <div className={'l'}>
                    <div>
                        <label>投注返点</label>
                        <span>{commission}</span>
                    </div>
                </div>
                <div>
                    <label>奖金组</label>
                    <span>{prize_group}</span>
                </div>
            </div>
            <div className={'rr'}>
                <div className={'rrr'}>
                    {display_bet_number}
                </div>
            </div>
        </div>
        {+d.status < 1 ?
            <div className={'stop-btn'} onClick={() => {
                stopTrace({id: d.id}, (r) => {
                    query({trace_id: id})
                    onChange&&onChange(id)
                })
            }
            }>停止追号</div>
            : ''
        }
        <div
            className={'trace-table'}>
            <Table className={"x-table"} data={res && res.data || []} row={tableConfig}/>
            <Loading act={loading}>正在加载...</Loading>
            <Loading act={stopDetailLoading}>正在取消该期追号...</Loading>
            <Paging data={{totalPage, stepPage, cPage, handleBtnClick}}/>
        </div>
        <Loading act={sTraceLoading}>正在终止...</Loading>
    </div>
}


export const TrcDetail = widthDashBoard(({history, location: {pathname, state: d = {}}}, {betStore}) => {
    const {lotteries} = betStore;
    return <div className={'his-detail'}>
        <DashTitle title={'投注记录-追号'} cls={'sm'}/>
        <div className={'g-back'} onClick={() => history.go(-1)}>返回列表</div>
       <TrcD ls={lotteries} d={d} onChange={()=>{
           history.replace({pathname, state: {...d, status: 2}});
       }}/>
    </div>
});
