import React, {useEffect, useState} from "react";
import  './p.scss';
export  const change = (c, f) => ({target: {value}}) => c(f ? f(value) : value);
export const Pwd = ({value, onChange, cls = '', placeholder,focus,blur}) => {
    const [t, tt] = useState(0);
    const [f, ff] = useState(0);
    const tp = ['password', 'text'][t];
    useEffect(() => {
        if (t) {
            const tm = setTimeout(() => tt(0), 2e3);
            return () => clearTimeout(tm);
        }
    }, [t]);
    return <div className={'pw ' + cls +(f?' focus':'')}>
        <input
            type={tp}
            onBlur={e=>{
                ff(0);
                blur&&blur(e)
            }}
            onFocus={e=>{
                ff(1)
                focus&&focus(e);
            }}
            placeholder={placeholder}
            value={value}
            autoComplete={"off"}
            onChange={change(onChange)}/>
        {value ? <i className={t ? 's' : ''} onClick={() => tt(1)}/> : ''}
    </div>
};
