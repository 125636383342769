import React, {useEffect, useMemo, useState} from 'react';
import {PopUpWin} from "../../components/popup/popup";
import {history, widthStore} from "../../store";
import {useQuery} from "../../hooks";
import {PopPwd} from "../account/pop";
import './s.scss';
import {panel} from "../../components/tabs";
import {change} from "../../components/pwd";
import {trim} from "../../utils";
import {px} from "../../store/global";
import {CCode, VerifyPhone} from "../../components/code";

const BkTip = () => {
    return <div className="pop-pw bk">
        <i className={'warn'}/>
        <p>为了您的资金安全，充值前请先绑定银行卡。</p>
        <div className={'btn'} onClick={() => {
            if (window.location.pathname !== '/bank') history.push('/bank');
            popSetting.hide();
        }}>立即绑卡
        </div>
    </div>
}

const VPh = widthStore(({globalStore: {is_valid_phone, phone}}) => {
    const [u, uu] = useState(0);
    const [pv, pp] = useState("86");
    const [bind, l2] = useQuery('smsCheck')
    const [v0, vv0] = useState('');
    const [v1, vv1] = useState('');
    const [send, l0] = useQuery('sms')
    const st = useMemo(() => {
        if (l0) return 1;
        if (px.t) return 2;
        if (px.phone) return 3;
        return 0
    }, [u, px, l0]);
    useEffect(() => {
        if (is_valid_phone) return;
        const t = setInterval(() => {
            uu(Math.random());
        }, 1000);
        return () => clearInterval(t);
    }, [is_valid_phone])
    const ok = v0 === px.phone && v0 && v1;
    const ok1 = VerifyPhone(v0,pv) && st !== 1 && st !== 2;
    return <div className="pop-pw ph">
        {is_valid_phone ? <div>
            <i className={'warn'}/>
            <p>
                您已绑定手机{(phone||px.phone||"").replace(/(\d\d\d)\d\d\d\d/, '$1****')},若要修改，请联系客服。
            </p>
            <div className={'btn'}>联系客服</div>
        </div> : <>
            <div className="r">
                <div className="pw pfx">
                    <i className={'i-ph'}><CCode change={pp} value={pv}/></i>
                    <input type="tel" placeholder="请输入手机号" value={v0}
                           onChange={change(vv0, a => a.replace(/[^\d]/g, ''))}/>
                    <div
                        onClick={() => {
                            if (ok1) {
                                const x = pv+'-'+v0;
                                send({phone: x}, () => {
                                    px.t = 60;
                                    px.phone = v0;
                                })
                            }
                        }}
                        className={ok1 ? 'act' : ''}>{['发送验证码', '正在发送', px.t + '秒后重试', '重新发送验证码'][st]}</div>
                </div>
                <label>手机号码:</label>
            </div>
            <div className="r">
                <div className="pw ">
                    <input placeholder="请输入验证码" value={v1} onChange={change(vv1, trim)} type={'text'}/>
                </div>
                <label>验证码:</label></div>
            <div className={'btn' + (ok ? '' : ' dis')}
                 onClick={ok ? (() => bind({code: v1})) : null}>{l2 ? '正在提交' : '确认'}</div>
        </>}
    </div>
});

export const popSetting = {}
export const PopChPwd = widthStore(({
                                        globalStore: {
                                            has_fund_password,
                                        }
                                    }) => {
    const [t, tt] = useState(0);
    const [k, kk] = useState(0);
    const [setPwd, pwdLoading] = useQuery('resetPwd');
    const [setFound, foundLoading] = useQuery(has_fund_password ? 'resetPwd' : 'resetFound');
    const ctx = useMemo(() => ({next: null}), [])
    const sub0 = (o, c) => {
        setPwd(o, v => {
            c(v);
            if (ctx.next) ctx.next();
        })
    };
    const sub1 = (o, c) => {
        if (has_fund_password) o.type = 1;
        setFound(o, v => {
            c(v);
            if (ctx.next) ctx.next();
        })
    }
    const isCpw = t === 6;
    return <PopUpWin
        onClose={() => kk(k + 1)}
        cls={'setting'} read={({show, hide}) => {
        popSetting.show = (a, b = () => {
        }, params) => {
            tt(a);
            ctx.next = b;
            if(params!==ctx.param){
                ctx.params = params;
                kk(k+1)
            }
            show()
        }
        popSetting.hide = () => {
            ctx.params = undefined
            hide()
        }
    }}>
        <div className={'ti'}>
            {['登录密码修改', '资金密码设置', '资金密码修改', '手机号码验证', '已完成', '请先绑定银行卡', '首次登陆请修改密码'][t]}
        </div>
        {panel(t < 3 || t === 6, <PopPwd
            key={'a' + k}
            old={t !== 1 && !isCpw}
            oldPwd={ctx.params}
            rule={/^(?=.*\d+)(?=.*[a-zA-Z]+)(?!.*?([a-zA-Z0-9])\1\1).{6,16}$/}
            tip={'6-16位字母数字组合且连续三位不能相同, 不能和'+(t?'用户':'资金')+'密码相同'}
            act={1}
            submit={t && !isCpw ? sub1 : sub0}
            close={() => popSetting.hide()}
            loading={t ? pwdLoading : foundLoading}
        />)}
        {panel(t === 3 || t === 4, <VPh key={'b' + k}/>)}
        {panel(t === 5, <BkTip/>)}
    </PopUpWin>
})


