export const pk10liangmianpanzhixuanrate_daccording = {
	balls:[
	    [-1,-1,-1,-1],
	    [-1,-1,-1,-1],
	    [-1,-1,-1,-1],
	    [-1,-1,-1,-1],
	    [-1,-1,-1,-1],
	    [-1,-1,-1,-1],
	    [-1,-1,-1,-1],
	    [-1,-1,-1,-1],
	    [-1,-1,-1,-1],
	    [-1,-1,-1,-1]
	],
    num:[
	    [1,0,3,2],
	    [1,0,3,2],
	    [1,0,3,2],
	    [1,0,3,2],
	    [1,0,3,2],
	    [1,0,3,2],
	    [1,0,3,2],
	    [1,0,3,2],
	    [1,0,3,2],
	    [1,0,3,2]
    ],
    views:[
	    ['大','小','单','双'],
	    ['大','小','单','双'],
	    ['大','小','单','双'],
	    ['大','小','单','双'],
	    ['大','小','单','双'],
	    ['大','小','单','双'],
	    ['大','小','单','双'],
	    ['大','小','单','双'],
	    ['大','小','单','双'],
	    ['大','小','单','双']
    ],
    titles: [
	    '冠军',
	    '亚军',
	    '第三名',
	    '第四名',
	    '第五名',
	    '第六名',
	    '第七名',
	    '第八名',
	    '第九名',
	    '第十名'
    ],
    getLottery:(num,balls)=>{
        let arr = [];
        for(let i = 0;i < balls.length;i++){
		    let str = "";
		    for(let l = 0;l < balls[i].length;l++){
			    if(balls[i][l] > 0){
				    str+=num[i][l]
			    }           
		    }
		    arr.push(str);
	    }
	    return  arr.join("|")
    },
    getNum:(balls)=>{
        let arr  = balls,num = 0;
		for(let i=0,len=arr.length;i<len;i++){
            for(let j=0,len_j=arr[i].length;j < len_j; j++){
			    if(arr[i][j]> 0){
				    num++;
			    }
		    }                    
        } 
        return num;
    },
    getView: (views,balls)=>{
        let arr = [];
        for(let i = 0;i < balls.length;i++){
		    let str = "";
		    for(let l = 0;l < balls[i].length;l++){
			    if(balls[i][l] > 0){
				    str+=views[i][l]
			    }           
		    }
		    arr.push(str);
	    }
	    return  arr.join("|")
    }
}
