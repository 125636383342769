import {useState, useMemo, useEffect, useCallback} from 'react';
import {stores} from '../store'
import {_query, query} from "../api";
import {getGameRule} from "../components/game";
import {halfUnit, limitBets, tip} from "../utils";
import {LAUNCH_CONFIG} from "../api/enum";
import {popWallet} from "../pages/pop/popWallet";

/**
 * 缓存hook结果
 * mobx 某些变量导致整个组件key变化 比如 token
 * @type {{}}
 */

const hookCache = {};
const {betStore, globalStore, betListStore} = stores;

export function clear() {
    Object.keys(hookCache).forEach(k => delete hookCache[k])
}

function hCache(key, m) {
    if (!key) return m;
    const a = hookCache[key] || m;
    hookCache[key] = a;
    return a;
}


/**
 * 自定义 React Hook
 * @param  {string} api api配置名称{@link apiConfig}
 * @param  {string} [cKey] 是否记忆结果
 * @return {Array} setQuery, loading, result, error
 */
export function useQuery(api, cKey) {
    let m = useMemo(() => hCache(cKey, {a: null, b: null}), [api, cKey]);
    const [ld, ll] = useState(0);
    const st = useMemo(() => {
        return {
            done: [],
            fail: [],
            loading: 0,
            tk: 0,
            clean() {
                this.done.length = this.fail.length = 0;
            },
            run(ok) {
                const ls = ok ? this.done : this.fail;
                return v => {
                    ls.forEach(fn => fn(v))
                }
            }
        }
    }, [api])

    useEffect(() => {
        return () => {
            st.clean();
        }
    }, [api])
    const setQuery = useCallback((param, success, error, cfg) => {
        st.done.push(v => {
            update(0, v, null);
        });
        st.fail.push(v => {
            update(0, null, v);
        });
        const update = (c, a, b) => {
            if (a !== undefined) m.a = a;
            if (b !== undefined) m.b = b;
            st.loading = c;
            st.tk++;
            ll(st.tk);
        };
        update(1)
        _query(api, cfg)(param, success, error).subscribe({
            next: st.run(1),
            error: st.run(0)
        });
        return () => {
            st.clean();
        };
    }, [api])
    return useMemo(() => {
        return [setQuery, st.loading, m.a, m.b]
    }, [setQuery, ld, api]);
}


/**
 * 游戏玩法钩子
 * @param loading
 * @param headerData
 * @return {*}
 */
export function useGameWay(loading, headerData) {
    const {way0, way1, way2} = betStore;
    const [way, setWay] = useState([{children: []}, {children: []}, {}]);
    useEffect(() => {
        const v = {children: []}
        if (!loading && headerData.length) {
            const ws = getGameWay(
                way0, way1, way2,
                'way0', 'way1', 'way2'
            );
            setWay(ws);
        } else setWay([v, v, v]);
    }, [loading, headerData, way0, way1, way2]);
    return way;
}

const myGames = {};

const gameBuilder = (name, o) => {
    const base = {
        num: null,
        mark: null,
        titles: null,
        position: null,
        positionView: null,
        positionMin: null,
        balls: [],
        views: [],
        limitBet: false,
        dantuo: false,
        dantuoNum: 1,
        isInput: 0,
        desc: '',
        eg: '',
        chooseType: [],
        checkBallIsComplete: null,
        getNum: () => null,
        getView: () => null,
        showTuoType: false,
        getLottery: null,
        limitBets
    };
    if (o) {
        Object.assign(base, o);
        myGames[name] = base;
        base.back = JSON.stringify(base);
        base.recover = () => Object.assign({}, base, JSON.parse(base.back));

        return base
    } else return myGames[name];
};


const _cache = {};

/**
 * 找到当前数组中id 为指定值的 成员 并将期赋值
 * 给 betStore[key],未找到会取值数组第一个
 * 取到的成员会作为参数回调
 * @param {Array} ls
 * @param {string} id 当前玩法id
 * @param {string} key betStore 中的值 e.g: 'way0'
 * @param {function} cb
 * @return {Object}
 */
function findWay(ls, id, key, cb) {
    let a;
    if (ls.length) {
        for (let i = 0, l = ls.length; i < l; i++) {
            const it = ls[i];
            if (!a) a = it;
            if (it.id === id) {
                return cb(it);
            }
        }
    }
    if (key && a) {
        try {
            if (betStore[key] !== a.id) betStore[key] = a.id;
        } catch (e) {
        }
        cb(a);
    } else {
        // 不应该到这里
        debugger;
    }
}

// 获取玩法
export function getGameWay(w0, w1, w2, k0, k1, k2) {
    // console.log('getGameWay', w0, w1, w2, k0, k1, k2);
    const key = [w0, w1, w2].join();
    const v = _cache[key];
    if (!k0 && v && v.length) return v;
    const {headerData = []} = betStore;
    let a = {children: []}, b = {children: []}, c = {};
    findWay(headerData, w0, k0, it => a = it);
    findWay(a.children, w1, k1, it => b = it);
    findWay(b.children, w2, k2, it => c = it);
    return _cache[key] = [a, b, c];
}

/**
 * 游戏规则钩子
 * @param type
 * @param gameName
 * @return {*}
 */
export function useGameRule(type, gameName) {
    const {gKey} = betStore;
    const rec = gKey && betListStore.betMap[gKey];
    const [notFound, nn] = useState(1)
    let tp = type;
    if (type === '3d' || type === '115') tp = "_" + type;
    const gn = tp + gameName;
    const setGame = (gn = '', g = {}) => {
        betStore.playGame = g;
        betStore.playName = gn;
    }
    useEffect(() => {
        const cfg = getGameRule(gn);
        if (!cfg) {
            setGame();
            nn(0);
        } else {
            const g = gameBuilder(gn, cfg);
            setGame(gn, g);
            if (rec) {
                betStore.recover(rec);
            }
        }
    }, [gn, rec])
    return useMemo(() => {
        return [notFound,
            (val, row) => {
                const game = betStore.playGame;
                const {dantuo, balls = []} = game;
                const cells = balls[row] || [];
                const fCells = balls[0] || [];
                const l = cells.length;
                const half = Math.floor(l / 2);
                const oddStart = game.views[0][0] % 2;
                game.limitBets(game.balls, () => {
                    for (let i = 0; i < l; i++) {
                        const odd = (i % 2) ^ oddStart;
                        switch (val) {
                            case '全':
                                if (dantuo) fCells[i] = -1;
                                cells[i] = 1;
                                break;
                            case '大':
                                if (dantuo && i >= half) fCells[i] = -1;
                                cells[i] = i >= half ? 1 : -1;
                                break;
                            case '小':
                                if (dantuo && i < half) fCells[i] = -1;
                                cells[i] = i < half ? 1 : -1;
                                break;
                            case '奇':
                                if (dantuo && odd) fCells[i] = -1;
                                cells[i] = odd ? 1 : -1;
                                break;
                            case '偶':
                                if (dantuo && !odd) fCells[i] = -1;
                                cells[i] = !odd ? 1 : -1;
                                break;
                            case '清':
                                cells[i] = -1;
                                break;
                            default:
                                console.log("default,文字配置错误");
                                return;
                        }
                    }
                });
                betStore.playGame = game;
            },
            (x, y) => {
                const game = betStore.playGame;
                const {limitBet, dantuo, balls, dantuoNum: limit} = game;
                if (x !== undefined) {
                    game.limitBets(balls, () => {
                        const cells = balls[x] || [];
                        const top = balls[0] || [];
                        const length = cells.length;
                        const v = cells[y];
                        cells[y] = v === 1 ? -1 : 1;
                        if (limitBet) {
                            for (let i = 0; i < length; i++) {
                                if (i !== y) cells[y] = -1;
                            }
                        } else if (dantuo) {
                            if (x) {
                                top[y] = -1
                            } else {
                                let num = 0, last = 0;
                                for (let i = 0; i < length; i++) {
                                    if (i === y) {
                                        if (v > 0) {
                                            cells[i] = -1;
                                            num--
                                        } else {
                                            if (num >= limit) {
                                                cells[last] = -1;
                                            } else {
                                                num++;
                                                cells[i] = 1;
                                                balls[1][i] = -1;
                                            }
                                        }
                                    } else {
                                        if (v > 0) {
                                            if (num >= limit) {
                                                cells[i] = -1;
                                            } else {
                                                num++;
                                            }
                                            last = i;
                                        }
                                    }
                                }
                            }
                        }
                    });
                }
            },
            (val, row) => {
                const game = betStore.playGame;
                const {dantuo, balls = []} = game;
                const cells = balls[row] || [];
                const fCells = balls[0] || [];
                const l = cells.length;
                const half = Math.floor(l / 2);
                const oddStart = game.views[0][0] % 2;
                for (let i = 0; i < l; i++) {
                    const odd = (i % 2) ^ oddStart;
                    switch (val) {
                        case '全':
                            if (dantuo) if (fCells[i] !== -1) return
                            if (cells[i] !== 1) return;
                            break;
                        case '大':
                            if (dantuo && i >= half) if (fCells[i] !== -1) return;
                            if (cells[i] !== (i >= half ? 1 : -1)) return;
                            break;
                        case '小':
                            if (dantuo && i < half) if (fCells[i] !== -1) return;
                            if (cells[i] !== (i < half ? 1 : -1)) return;
                            break;
                        case '奇':
                            if (dantuo && odd) if (fCells[i] !== -1) return;
                            if (cells[i] !== (odd ? 1 : -1)) return;
                            break;
                        case '偶':
                            if (dantuo && !odd) if (fCells[i] !== -1) return;
                            if (cells[i] !== (!odd ? 1 : -1)) return;
                            break;
                        default:
                            return
                    }
                }
                return 1;
            }
        ]
    }, [notFound]);
}

const cp = a => JSON.parse(JSON.stringify(a));
const getCurrentGameDate = (game) => {
    const {
        way0, way1, way2, bets,
        moneyunit, mulriple, cnName = [],
    } = betStore;
    const gKey = betStore.gKey || 'gk-' + Date.now() + Math.random();
    const {balls, position, isInput, positionView} = game;
    const view = game.getView(game.views, game.balls);
    const ball = game.getLottery(game.num, game.balls);
    const bs = isInput ? balls : balls.concat();
    const ps = position && position.concat();
    return {
        ways: [way0, way1, way2],
        balls: cp(bs),
        position: cp(ps),
        gKey: gKey,
        bets,
        moneyunit,
        mulriple,
        ball,
        view,
        prizeGroup: betListStore.prizeGroup,
        title: cnName.join(','),
        usePosition: positionView && positionView.length,
        get amount() {
            return Number(bets) * 2 * this.mulriple * this.moneyunit;
        },
    }
};

export function useGameFoot(game, max_multiple) {
    // console.log('useGameFoot');
    const {moneyunit, mulriple, max_multiple_old, bets} = betStore;
    const max = max_multiple / moneyunit;
    let mul = Math.min(mulriple || 1, max);
    useEffect(() => {
        if (mul !== mulriple) {
            betStore.mulriple = mul;
        }
    }, [mul]);
    if (!game) return [];
    const {balance} = globalStore;
    const single = bets * 2 * 100 * moneyunit / 100;
    const money = (mulriple * single).toFixed(2);
    const maxAllow = Math.floor(single && (balance / single));
    const setUn = i => betStore.moneyunit = i;

    const addMu = () => {
        const s = 1;
        if (isNaN(max)) {
            return;
        }
        if (mulriple + s <= max) {
            betStore.mulriple = parseInt(mulriple) - mulriple % s + s
        }
    };
    const minusMu = () => {
        const s = 1;
        if (mulriple >= s * 2) {
            betStore.mulriple = Math.min(parseInt(mulriple) - mulriple % s, max) - s
        }
    };
    const setMu = value => {
        if (value !== '' && !isNaN(value)) {
            betStore.max_multiple_old = value;
            if (value > max) {
                value = max;
            } else {
                betStore.max_multiple_old = value;
            }
        } else {
            value = max_multiple_old;
        }
        if (value < 1) value = 1;
        betStore.mulriple = value
    };
    const clear = () => {
        betStore.gKey = null;
        betStore.playGame = game.recover();
    };
    const addCart = () => {
        betListStore.addToCart(getCurrentGameDate(game));
        clear();
    };
    const allIn = () => {
        betStore.mulriple = Math.min(maxAllow, max);
    };
    let av = ' ' + balance;
    av = parseFloat(av);
    if (isNaN(av)) av = '--';
    else av = av.toFixed(2);
    // 奖金组
    const {prize_group, max_bet_prize_group, min_bet_prize_group} = globalStore;
    const prizeGroup = Math.min(max_bet_prize_group, prize_group);
    const prizeLis = [min_bet_prize_group, prizeGroup];
    const prizeChange = (v) => {
        betListStore.prizeGroup = Math.max(Math.min(prizeGroup, v), min_bet_prize_group);
    };
    const selectPrize = betListStore.prizeGroup;
    if (selectPrize < min_bet_prize_group || selectPrize > max_bet_prize_group) {
        prizeChange(prize_group);
    }
    return [
        setUn, moneyunit, mul, money,
        max, av, setMu, addMu,
        minusMu, maxAllow, allIn,
        addCart, prizeLis,
        prizeChange, selectPrize, prize_group
    ]
}

/**
 * 投注提交钩子
 * @param {(Number|String)} gameId 当前游戏Id
 * @param {(Boolean|Number)} [cur] 提交当前 1- 当前 2 - 购物车
 * @return {*}
 */
export function useBetSubmit(gameId, cur) {

    function submit() {
        const {trRateLis, trSameMultipleLis, trMultipleLis, betList, betMap, traceType} = betListStore;
        const balls = [];
        const orders = {};
        const sendData = {amount: 0, balls, isTrace: 0, orders, gameId: +gameId};
        const {current_number} = betStore;
        let isPKMode = 1;
        orders[current_number] = 1;

        function add(games, m) {
            games.forEach(game => {
                const {ball, amount, moneyunit, mulriple, prizeGroup, bets, ways: [w0, w1, w2], position} = game;
                sendData.amount += m ? amount / mulriple : amount;
                if (isPKMode) {
                    const ws = (getGameWay(w0, w1, w2) || [])[2] || {};
                    const {aProbilities: {probability = []} = {}} = ws;
                    const p = Math.min(...probability) * bets;
                    if (p > 0.03) isPKMode = 0;
                } else {
                    isPKMode = 0;
                }
                const half = halfUnit(moneyunit);
                balls.push({
                    ball,
                    moneyunit: half ? moneyunit / 5 : moneyunit,
                    multiple: m ? (half ? 5 : 1) : (half ? mulriple * 5 : mulriple),
                    num: bets,
                    onePrice: 2,
                    wayId: w2,
                    position: position,
                    prize_group: +prizeGroup,
                });
            })
        }

        const actTrace = [trRateLis, trSameMultipleLis, trMultipleLis][traceType];
        if (cur) {
            const game = getCurrentGameDate(betStore.playGame);
            add([game], actTrace.length);
        }
        add(betList.map(k => betMap[k]), actTrace.length);
        console.log(balls)
        if (actTrace && actTrace.length) {
            sendData.isTrace = 1;
            let t = 0;
            actTrace.forEach(({traceNumber, multiple, sel}) => {
                if (sel) {
                    t++;
                    orders[traceNumber] = multiple;
                }
            });
            sendData.amount *= t;
        }
        const {balance} = globalStore;
        if (balance && sendData.amount > balance) return tip.alert({
            title: '提示',
            text: "余额不足",
            maskCloseAble: 1,
            mask: 1,
            yes: "去充值",
            no: '确定',
            onClose: i => {
                if (i) popWallet.show(1)
            }
        });
        // console.log(sendData)
        //暂时注释投注请求。
        const next = () => {
            const o = query('bet', sendData).subscribe({
                next() {
                    if (cur) {
                        betStore.gKey = '';
                        betStore.playGame = betStore.playGame.recover();
                    } else {
                        betListStore.clearList();
                        betListStore.clearTrace();
                    }
                    o.unsubscribe();
                }
            });
        }
        if (isPKMode && !localStorage.pkTip) {
            tip.alert({
                title: '提示',
                align: 'left',
                check: "不再提示",
                text: "中奖概率小于或等于3%的均视为单挑。单挑同期同一玩法、同一位置，" +
                    "最高中奖2万元，超出金额将会扣除。同期所有单挑玩法的注单合计派奖2万元。",
                maskCloseAble: 1,
                mask: 1,
                yes: "确认提交",
                no: true,
                onClose: (i, c) => {
                    if (c) localStorage.pkTip = 1;
                    if (i) next();
                }
            });
        } else next();
    }

    return useCallback(submit, [gameId, cur])
}


/**
 * 利润率追号
 * 原版逻辑搬运~ 我也不造具体业务规则
 * @param xRate
 * @param prize
 * @param sumAmount
 * @param xTimes
 * @param xMultip
 * @param xMaxnum
 * @return {Array}
 */
function returnRate(xRate, prize, sumAmount, xTimes, xMultip, xMaxnum) {
    let sum = xMultip * sumAmount, c = [];
    for (let u = 0; u < xTimes; u++) {
        const o = Math.ceil(sum * (1 + xRate) / (prize - sumAmount * (1 + xRate)));
        if (1 > o) break;
        const mu = o > xMaxnum ? xMaxnum : o;
        if (u === 0) sum = mu * sumAmount;
        else sum += mu * sumAmount;
        c.push({
            multiple: mu,
            amountAll: sum,
            winAmountAll: prize * mu - sum,
            oldMultiple: o
        });
    }
    return c
}

export const useTrace = (ls = [], type) => {
    // console.log('useTrace');
    const [err, setErr] = useState('');
    const {
        traceIssues, traceReturnRate, traceMultiple,
        tractStep, traceOp, traceOpMultiple, traceTimes,
        prizeGroup, traceType,
    } = betListStore;
    useEffect(() => {
        setErr('');
    }, [type]);
    const trace = () => {
        setErr('');
        betListStore.clearTrace(type);
        let sumPrize = 0, sumAmount = 0, unit = 0;
        const l = ls.length;
        if (!l) setErr('请至少选择一注投注号码！');
        let way;
        const mxLs = [];
        for (let i = 0, b; i < l; i++) {
            const item = ls[i];
            const {ways, moneyunit, prizeGroup, bets, ball} = item;
            if (!unit) unit = moneyunit;
            const k = ways.join() + moneyunit;
            if (b && b !== k) {
                if (traceType === 0) return setErr('利润率追号不支持混投，' +
                    '请确保您的投注都为同一玩法类型，' +
                    '且元角模式一致。')
            } else b = k;
            const ws = getGameWay(...ways);
            if (!way) way = ws;
            mxLs.push(way[2].getMaxMultiple(prizeGroup));
            sumPrize += ws[2].getPrize(prizeGroup, [ball, bets])[0] * moneyunit;
            sumAmount += bets * 2 * moneyunit;
        }
        const trData = [];
        if (way) {
            const ll = [];
            const max = Math.min(...mxLs) / unit;
            if (traceType === 0) {
                ll.push(...returnRate(
                    traceReturnRate / 100,
                    sumPrize,
                    sumAmount,
                    traceTimes,
                    traceMultiple,
                    max
                ));
                if (ll.length < 1) return setErr('您设置的参数无法达到盈利，请重新设置。');
                const o = ll.find(({oldMultiple}) => oldMultiple > max);
                if (o) setErr('生成方案中的倍数超过了系统最大允许设置的倍数，将自动调整为系统最大可设置倍数。');
                ll.forEach(({multiple, amountAll, winAmountAll}, i) => {
                    const o = traceIssues[i];
                    if (o) {
                        trData.push({
                            max,
                            err: false,
                            sel: true,
                            traceNumber: o.number,
                            multiple: multiple,
                            price: sumAmount,
                            prize: sumPrize,
                            winAmountAll: winAmountAll,
                            rate: Number(winAmountAll / amountAll * 100).toFixed(2)
                        })
                    }
                });
                betListStore.trRateLis = trData;
            } else {
                const base = {
                    sumAmount: 0,
                    multiple: Math.min(max, traceMultiple),
                    max: 0
                };
                const maxLs = [];
                ls.forEach(item => {
                    const {ways, amount, mulriple, moneyunit, prizeGroup} = item;
                    const ws = getGameWay(...ways);
                    const max = ws[2].getMaxMultiple(prizeGroup) / moneyunit;
                    maxLs.push(max);
                    base.sumAmount += amount / mulriple;
                });
                base.max = Math.min(...maxLs);
                const maxTrace = Math.min(traceIssues.length, traceTimes);
                for (let i = 0, mu = +base.multiple; i < maxTrace; i++) {
                    const o = traceIssues[i];
                    const b = {
                        max: base.max,
                        sel: true,
                        traceNumber: o.number,
                        multiple: base.multiple,
                        price: base.sumAmount,
                        time: o.time,
                    };
                    if (i && (traceType === 2)) {
                        if (i % tractStep === 0) {
                            if (traceOp) mu *= +traceOpMultiple;
                            else mu += +traceOpMultiple;
                        }
                        if (mu > max) mu = max;
                        b.multiple = mu;
                    }
                    trData.push(b)
                }
                if (traceType === 1) betListStore.trSameMultipleLis = trData;
                else betListStore.trMultipleLis = trData;
            }
        }
    };
    return useMemo(() => [trace, err, () => setErr('')], [ls, type, prizeGroup])
};
export const useThirdGame = (tp, sort = [], suffix = '') => {
    const [a, ld] = useQuery('thirdProduct');
    useEffect(() => {
        a()
    }, [])
    return useMemo(() => {
        const cc = [];
        const ffp = tp==='elect'?'sport':tp;
        const ns = LAUNCH_CONFIG[ffp] || {};
        const {thirdGameStore, walletStore} = stores;
        const {availableGames, productType} = thirdGameStore;
        if(tp==='lottery'){
            return [
                ['bt', 'bt', 'bt', 'BT彩票', 0, 0, 0],
                ['tcg', 'tcg', 'tcg', '盘口彩票', 0, 0, 0],
            ]
        }else if( tp==='rng'){
            return (availableGames.rng||[]).map(a=>{
                const id = productType[a]
                return [a,'rng-'+id,id,id+'电子',0,0,0]
            })
        }
        (availableGames[ffp] || []).filter(a => {
            if(tp==='elect'){
                return  /24|27|26/gi.test(a)
            }
            return tp !== 'sport' || !/24|27|26/.test(a)
        }).sort((a, b) => {
            if (sort && sort.length) {
                const x = sort.indexOf(a);
                const y = sort.indexOf(b);
                if (x === -1) return -1;
                if (y === -1) return 1;
                return x > y ? 1 : -1;
            }
            return 0;
        }).forEach(k => {
            const cfg = ns[k];
            if (cfg) {
                const [id, img, name, ptf, win] = cfg;
                const nm = name || (productType[k] + suffix);
                const bls = (walletStore.bls || {})[k] || {}
                cc.push([k, id, img, nm, bls, ptf, win])
            }
        });
        return cc;
    }, [tp, ld]);
}
