export const pk10budingweisanxingbudingweihousan = {
	balls:[
	    [-1,-1,-1,-1,-1,-1,-1,-1,-1,-1]
	],
    num:[
	    [0,1,2,3,4,5,6,7,8,9]
    ],
    views:[
	    [1,2,3,4,5,6,7,8,9,10]
    ],
    titles: [
	    '后三',
    ],
    chooseType:['全','大','小','奇','偶','清'],
    getLottery:(num,balls)=>{
        let str = '';
        for(let i = 0,len = balls[0].length;i < len;i++){
            if(balls[0][i] > 0){
                str+=num[0][i];
            }           
        }
        return  str;
    },
    getNum:(balls)=>{
        let arr  = balls,num = 0;
        for(let i=0,len=arr.length;i<len;i++){
            for(let j=0,len_j=arr[i].length;j < len_j; j++){
                if(arr[i][j]> 0){
                    num++;
                }
            }                    
        }
        return num;
    },
    getView: (views,balls)=>{
        let str = '';
	    for(let i = 0,len = balls[0].length;i < len;i++){
            if(balls[0][i] > 0){
                str+= views[0][i];
            }           
        }
        return str;
    }
}
