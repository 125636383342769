const mp = {};
export const myBankStore = {
    islock: null,
    banks: [],
    usdts: [],
    allBanks: [],
    get bankMap() {
        return mp;
    },
    set bankMap(v) {
        if (Array.isArray(v)) {
            v.forEach(n => {
                mp[n.id] = n
            })
        }
    }
};