import React, {useState, useEffect} from 'react';
import './lr.scss'
import {Portals} from "../../components/portals";
import {tip} from "../../utils";
import {history, widthStore} from "../../store";
import {SignWin} from "../../components/login";
const home = '/agent/analysis'
let tim = -1;
let tim1 = -1;
export const PopLogin = widthStore(({globalStore}, {
    pageMode
}) => {
    const [v, sV] = useState(0);
    const [mo, sM] = useState(+pageMode);
    const {token} = globalStore
    const mod = token ? +pageMode : mo;
    useEffect(() => {
        /**
         * 登录弹窗
         * @param {Number} [mod]
         */
        tip.popLogin = (mod = 1) => {
            tip.popStatus = mod;
            clearTimeout(tim1);
            tim1 = setTimeout(() => sM(mod), 100)
        };
    }, []);
    useEffect(() => {
        clearTimeout(tim);
        if(pageMode){
            if(token){
                return  history.replace(home)
            }
        }
        tim = setTimeout(() => sV(token ? 0 : mo), 60);
        if (!mod) sM(0);
        return () => clearTimeout(tim);
    }, [mod, token, pageMode]);
    const act = pageMode || (v && mod);
    const o = <div className={'lr-mask' + (act ? ' act' : '')}>
        <SignWin
            pageMode={pageMode}
            key={mod} type={mod - 1} change={pageMode ? a => {
                if(a===0){
                    history.push(home)
                }else sM(a)
            } : a => {
            tip.popLogin(a)
        }}/>
    </div>
    return pageMode ? o : (mod || v) ? <Portals>
        {o}
    </Portals> : null
});
