import React, {useEffect, useMemo, useState} from 'react';
import {useQuery} from "../../hooks";
import {change} from "../../components/pwd";
import {Loading} from "../../components/loading";
import {panel} from "../../components/tabs";
import {widthStore} from "../../store";

const maxLv = 4;
const M = ({v, vv, p, er, ck}) => {
    let erTxt = '';
    const un = (/（.*?(.)）/.exec(p) || [])[1] || ''
    if (er > 2) erTxt = '不能低于' + (er - 3) + un;
    if (er < 0) erTxt = '不能高于' + (-er) + un;
    return <div className={'v' + (er ? ' er' : '')}>
        <input
            onFocus={ck()}
            onBlur={ck(1)}
            type={'text'}
            placeholder={p}
            value={v}
            onChange={change(vv, a => a && (parseFloat(a.replace(/[^0-9.]/g, '')) || v))}/>
        {er ? <span onClick={ck()}>{v !== '' ? erTxt : '不能为空'}</span> : null}
    </div>
}
const K = ({data = [], i, limit, userId, max, readOnly, onChange, onTmpChange, onErr}) => {
    const da = data[i]
    const [v0, vv0] = useState('');
    const [v1, vv1] = useState('');
    const [v2, vv2] = useState('');
    const [e0, ee0] = useState(0);
    const [e1, ee1] = useState(0);
    const [e2, ee2] = useState(0);
    const [del, dLd] = useQuery('delBonus');
    const [add, aLd] = useQuery('addBonus');
    const last = data[i - 1];
    const [a = '', b = '', c = ''] = da || [];
    const submit = (tmp = 0) => () => {
        if (aLd) return;
        if (!(e0 + e1 + e2)) {
            if (v0 && v1 && v2) {
                if (!tmp) {
                    onTmpChange(null);
                    vv1('');
                    vv2('');
                    vv0('');
                }
                if (userId) {
                    add({
                        rate: v2,
                        active_number: v1,
                        deficit: v0,
                        user_id: userId
                    }, ({id}) => {
                        [onChange, onTmpChange][tmp]([v0, v1, v2, id]);
                    })
                } else [onChange, onTmpChange][tmp]([v0, v1, v2]);
            } else if (v0 || v1 || v2) {
                if (!tmp) {
                    if (!e0) check(0)(1)();
                    if (!e1) check(1)(1)();
                    if (!e2) check(2)(1)();
                }
            }
        }
    }
    const d = JSON.stringify(data);
    useEffect(() => {
        if (onErr) {
            const mx = Math.max(e0, e1, e2);
            const mi = Math.min(e0, e1, e2);
            if (mi < 0) onErr(mi)
            else onErr(mx);
            if (e0) check(0)(1)();
            else if (e1) check(1)(1)();
            else if (e2) check(2)(1)();
            else onErr(0);
        }
    }, [d, e0, e1, e2])
    const check = n => k => () => {
        onTmpChange([v0, v1, v2]);
        const m = +(last || [])[n] || -1;
        const er = [ee0, ee1, ee2][n];
        const e = [e0, e1, e2][n];
        const v = [v0, v1, v2][n];
        if (!k) return e && er(0);
        if (!v) return !e && er(1);
        if ((v < 0 || v <= m) && n !== 1) return !e && er(4 + m);
        if (n === 1 && v < limit) return !e && er(3 + limit);
        if (n === 2) {
            if (v > max) return !e && er(-max);
        }
        if (e) er(0);
        if (!userId) submit(1)();
    }
    const isLast = i + 1 === data.length;
    return <div className={'k'} key={i}>
        <label>
            级别{i + 1}
        </label>
        {panel(da, <>
            <div>周期亏损:{a}元</div>
            <div>活跃人数:{b}人</div>
            <div>分红比例:{c}%</div>
            {readOnly ? null : !isLast ? <div className={'btn none'}/> :
                <div className={'btn del'} onClick={() => {
                    if (dLd) return;
                    const id = da[3]
                    if (userId) del({
                        user_id: userId,
                        id,
                    }, () => {
                        onChange(i);
                    })
                    else onChange(i);
                }}>{dLd ? '删除中' : '删除'}</div>}
        </>)}
        {panel(!da, <>
            <M v={v0} vv={vv0} p={'周期亏损（元）'} ck={check(0)} er={e0}/>
            <M v={v1} vv={vv1} p={'活跃人数（>=' + limit + '人）'} ck={check(1)} er={e1}/>
            <M v={v2} vv={vv2} p={'分红比例（%）'} ck={check(2)} er={e2}/>
            <div className={'btn'} onClick={submit()}>{aLd ? '添加中' : '添加+'}</div>
            {aLd ? <div className={'msk'}/> : null}
        </>)}
    </div>
};

export const Dividend = widthStore(({globalStore: {user_level}}, {onChange, id, userId, readOnly, onStatusChange}) => {
    const [lvs, ll] = useState([]);// 分红设定
    const [on, oo] = useState((userId || readOnly) ? 1 : 0);// 激活
    const [tmp, tt] = useState(null);
    const [query, loading, data] = useQuery('userSetting');
    const [query1, loading1, data1] = useQuery('getBonus');
    const [er, eer] = useState(0);
    const [pt, opt] = useState(0);
    const st = useMemo(() => ({}), []);

    useEffect(() => {
        if (userId && pt) {
            const s = lvs.length ? 1 : 0;
            const d = {
                status: s,
                user_id: userId,
                id
            }
            if (s !== st.s) {
                st.s = s;
                onStatusChange(d)
            }
        }
    }, [lvs, userId, pt, id])
    useEffect(() => {
        query()
        if (userId) query1({user_id: userId})
    }, [userId]);
    const {
        bonus_rate
    } = data || {};
    const limit = user_level >= 1 ? 1 : 5;
    const rate = data1 || [];
    useEffect(() => {
        if (!loading1 && data1 && userId) {
            ll(rate.map(({deficit, rate, active_number, id}) => [deficit, active_number, rate, id]))
        }
    }, [loading1, data1, userId]);
    useEffect(() => {
        if (!loading && data && !userId && readOnly) {
            bonus_rate.sort((a, b) => {
                return a.rate > b.rate ? 1 : -1
            })
            ll(bonus_rate.map(({deficit, rate, active_number}) => [deficit, active_number, rate]))
        }
    }, [loading, data, userId, readOnly]);
    const convert = () => {
        const s = lvs.slice();
        if (tmp && tmp[0] && tmp[1] && tmp[2]) s.push(tmp);
        return s.map(([deficit, active_number, rate]) => ({deficit, rate, active_number}));
    };
    useEffect(() => {
        let sss = 0
        const d = {data: {bonus_rate: [], er: 0}}
        if (on) {
            sss = er;
            if (tmp) {
                const [a, b, c] = tmp;
                const e = (+!a) + (+!b) + (+!c);
                if (e > 0 && e < 3) sss = 1;
                else if (sss === 1) sss = 0
            }
            if (!sss) {
                d.data.bonus_rate = convert()
            }
            d.er = sss;
        }
        onChange(d);
    }, [lvs, tmp, on, er])

    const minR = (b) => {
        return (b && b.length) ? Math.min(...(b || []).map(({rate}) => rate)) : 0;
    };
    const maxR = (b) => {
        return (b && b.length) ? Math.max(...(b || []).map(({rate}) => rate), 0) : 0;
    };
    const ch = v => {
        if (Array.isArray(v)) {
            const s = lvs.slice();
            s.push(v);
            ll(s);
        } else {
            const s = lvs.slice();
            s.splice(v, 1);
            ll(s);
        }
        opt(pt + 1)
    }
    //提示：您当前分红
    //                 比例为：{maxR(bonus_rate)}%，最多可设定4个级别，每个级别设定需要高过前一级别
    const lvl = lvs.length;
    const min = minR(bonus_rate);
    let shoreMore = !readOnly && lvl < maxLv;
    if (shoreMore && lvl && lvs[lvl - 1][2] === min) shoreMore = 0;
    return <div className={'dividend-cfg'}>
        <div className={'br'}>
            <div className={'b0'}>
                <i/><label>
                分红设定<span>DIVIDEND</span>
            </label>
                <div className={'x-ss'}/>
                {readOnly ? '' : <><s/>
                    <p>
                        {userId ?
                            `可设置的最高分红比例为：${minR(bonus_rate)}%`
                            : <>
                                您当前分红比例为：{minR(bonus_rate)}%~{maxR(bonus_rate)}%，是否签约分红？<b className={(on ? 'act' : '')}
                                                                                           onClick={() => {
                                                                                               if (on) onChange({});
                                                                                               oo(1 - on)
                                                                                           }}/>
                            </>}
                    </p>
                </>
                }
            </div>
        </div>
        {
            on ? <div className={'ls'}>
                {lvs.map((_, i) => <K readOnly={readOnly} userId={userId} key={i} i={i} data={lvs} onChange={ch}/>)}
                {shoreMore ?
                    <K
                        limit={limit}
                        onErr={v => eer(v)}
                        onChange={ch}
                        userId={userId}
                        onTmpChange={tt}
                        data={lvs}
                        i={lvs.length}
                        max={minR(bonus_rate)}/> : null}
            </div> : null
        }
        <Loading act={on && (loading1 || loading)}>正在加载信息...</Loading>
    </div>
})
