export const k3k3k3sanbutonghao = {
	balls:[
	   [-1,-1,-1,-1,-1,-1,-1,-1,-1,-1,
	 -1,-1,-1,-1,-1,-1,-1,-1,-1,-1]
	],
    num: [
	   [123,134,146,236,345,
	 124,135,156,245,346,
	 125,136,234,246,356,
	 126,145,235,256,456]
    ],
    views: [
	   [123,134,146,236,345,
	 124,135,156,245,346,
	 125,136,234,246,356,
	 126,145,235,256,456]
    ],
    titles: [
	  '三不同号'
    ],
    getNum:(balls)=>{
        let num = 0,arr = balls[0];
        for(let i=0,len=arr.length;i<len;i++){
            if(arr[i]>0){
                num++;
            }
        }
        return num;
    },
}
