function near(cur, before = 0, min = 0, max = Number.MAX_SAFE_INTEGER) {
    min = min / 100;
    max = max / 100;
    const o = [1, 4, 6, 7, 9];
    const m = cur % 10;
    if (o.indexOf(m) > -1) {
        if ((m === 4 || m === 9) && max - cur > 2) return cur + 1;
        if ((m === 6 || m === 1) && cur - before > 2 && cur - min > 1) return cur - 1;
        if (m === 7 && cur - before > 3 && cur - min > 2) return cur - 2;
    }
    return cur;
}


// 获取子分类图标样式
export const channelStore = {
    limit: 0,
    data: {},
    bankUrls: {},
    hundred: 0,
    payRes: {},
    payType: '',
    payTime: 0,
    mode: -1,
    ch0: -1,
    ch1: -1,
    ch2: -1,
    max: 0,
    min: 0,
    tip: '',
    clearRes() {
        this.payType = '';
        this.payTime = 0;
        this.payRes = {};
        return this;
    },
    getCh0() {
        const {ch0, data} = this;
        return Object.keys(data).map((k, i) => {
            const {id, name,identifier} = data[k];
            if (!i && !data[ch0]) {
                this.ch0 = id;
                this.ch1 = -1;
                this.ch2 = -1;
                this.clearRes();
            }
            return {
                id, name, act: id === ch0,identifier
            }
        });
    },
    getCh1() {
        const getCls = idf => {
            const o = [
                [/^(net)bank/i, 'netBank', 30],
                [/^(unionqr)bank/i, 'netBank no', 30],
                [/alipaybank/i, 'alipay bk', 5],
                [/wxbank/i, 'weixin bk', 5],
                [/weixin|wechat|wx/i, 'weixin', 0],
                [/alipay/i, 'alipay', 5],
            ];
            const s = (o.find(([reg]) => reg.test(idf)) || []);
            this.limit = s[2];
            return s[1]
        };
        const {ch0, ch1, data} = this;
        const ch0Da = data[ch0]||{};
        const {children: da = []} = ch0Da;
        return da.map((d, i) => {
            const {id, name} = d;
            if (!i && !da.find(({id}) => id === ch1)) {
                this.clearRes();
                this.ch1 = id;
                this.ch2 = -1;
            }
            const act = id === ch1;
            const {identifier, deposit_mode} = d;
            if (act) {
                this.hundred = /weifu/i.test(identifier);
                this.mode = deposit_mode;
            }
            return {
                id, name, act, cls: getCls(identifier)||getCls(ch0Da.identifier)
            }
        });
    },
    getCh2() {
        const {ch0, ch1, ch2, data} = this;
        const {children: da = []} = data[ch0]||{};
        const {banks = [], max_load, min_load} = da.find(({id}) => id === ch1)||{};
        this.min = min_load || 0;
        this.max = max_load || 0;
        return banks.map(({
                              identifier, id, name,
                              min_load = this.min, max_load = this.max
                          }, i) => {
            if (!i && !banks.find(({id}) => id === ch2)) {
                this.clearRes();
                this.ch2 = id;
            }
            const act = id === ch2;
            return {
                id, name, act, cls: identifier
            }
        });
    },
    getInfo() {
        const {hundred, max, min, limit} = this;
        if (hundred) {
            let [m, n] = [parseInt(min / 100), parseInt(max / 100)];
            const step = (n - m) / 27;
            return [m + step * 2, m + step * 4, m + step * 8, m + step * 16]
                .map(c => parseInt(c))
                .map((c, i, a) => 100 * near(c, a[i - 1], min, max));
        }
        const d = [{max, min, limit}];
        const r = [];
        if (min) r.push({name: '最低限额', cell: () => min + '元'});
        if (max) r.push({name: '最高限额', cell: () => max + '元'});
        if (limit) r.push({name: '时限', cell: () => limit + '分钟'});
        return r.length ? [d, r] : [];
    }
};