const fn = (v="|")=>(num,balls)=>{
    let str = [];
    for(let i = 0;i < balls.length;i++){
        for(let l = 0;l < balls[i].length;l++){
            if(balls[i][l] > 0){
                str.push(num[i][l])
            }
        }
    }
    return str.join(v);
};
export const k3k3k3daxiaodanshuang =  {
    balls:[
        [-1,-1,-1,-1]
    ],
    num:[
        [1,0,3,2]
    ],
    views:[
        ['大', '小', '单', '双']
    ],
    titles: ['大小单双'],
    getLottery:fn(),
    getNum:(balls)=>{
        let num=1,arr = [];
        for(let i=0,len=balls.length;i<len;i++){
            arr[i] = 0;
            for(let j=0,len_j=balls[i].length;j<len_j;j++){
                if(balls[i][j]>0){
                    arr[i]++;
                }
            }
        }
        for(let k=0,len_k=arr.length;k<len_k;k++){
            num*= arr[k];
        }
        return num;
    },
    getView: (views,balls)=>{
        let str = '';
        for(let i = 0;i < balls.length;i++){
            for(let l = 0;l < balls[i].length;l++){
                if(balls[i][l] > 0){
                    str +=views[i][l];
                }
            }
        }
        return str;
    }
}
export const k3k3k3kuadudaxiaodanshuang = {
    ...k3k3k3daxiaodanshuang,
    titles: ['跨度大小单双'],
};
export const k3k3k3kuadudaxiao = {
    ...k3k3k3daxiaodanshuang,
    getLottery:fn(""),
    titles: ['跨度大小'],
    balls:[
        [-1,-1]
    ],
    num:[
        [1,0]
    ],
    views:[
        ['大', '小']
    ],
};
export const k3k3k3kuadudanshuang = {
    ...k3k3k3daxiaodanshuang,
    getLottery:fn(""),
    titles: ['跨度单双'],
    balls:[
        [-1,-1]
    ],
    num:[
        [3,2]
    ],
    views:[
        [ '单', '双']
    ],
};