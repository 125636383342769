import React, {useState} from "react";
import {widthDashSheet} from "../../components/sheet";
import {change} from "../../components/pwd";
import {MyDatePicker} from "../../components/dashboard/gameRecord/MyDatePicker";
import {colHandel, convertDate, isAgentPage, trim} from "../../utils";
import {CheckBox, MoreDate, PrizeNum} from "../common/com";
import {Select} from "../../components/select/select";
import './p.scss'

export const ProfitPage = widthDashSheet((sheet, {globalStore}, {userClick, isDirect}) => {
    const [s0, ss0] = useState(0);
    const [s1, ss1] = useState(0);
    const [v1, vv1] = useState('');
    const [v2, vv2] = useState(1);
    const {user_level} = globalStore
    const now = Date.now();
    const old = convertDate(now - 1e3 * 3600 * 24);
    const [times, changeTim] = useState({
        start: old,
        end: convertDate(now),
    });
    const mCfg1 = [
        ['真', 'profit_live'],
        ['体', 'profit_sport'],
        ['彩', 'profit_lottery'],
        ['电', 'profit_rng'],
        ['棋', 'profit_pvp'],
        ['捕', 'profit_fish'],
    ]
    const mCfg = [
        ['真', 'turnover_live'],
        ['体', 'turnover_sport'],
        ['彩', 'turnover_lottery'],
        ['电', 'turnover_rng'],
        ['棋', 'turnover_pvp'],
        ['捕', 'turnover_fish'],
    ]
    const mCf3 = [
        ['真', 'dividend_live'],
        ['体', 'dividend_sport'],
        ['彩', 'dividend_lottery'],
        ['电', 'dividend_rng'],
        ['棋', 'dividend_pvp'],
        ['捕', 'dividend_fish'],
    ]
    const mCfg2 = [
        ['真', 'valid_turnover_live'],
        ['体', 'valid_turnover_sport'],
        ['彩', 'valid_turnover_lottery'],
        ['电', 'valid_turnover_rng'],
        ['棋', 'valid_turnover_pvp'],
        ['捕', 'valid_turnover_fish'],
    ]
    const cols = {
        12: ['团队人数', 'team_numbers'],
        0: ['充值', 'deposit'],
        1: ['提款', 'withdrawal'],
        2: ['投注', (d) => <MoreDate data={d} cfg={mCfg} act={!s1}>{d.turnover}</MoreDate>],
        3: ['投注', (d) => <MoreDate data={d} cfg={mCfg} act={!s1}>{d.betAmount}</MoreDate>],
        4: ['派奖', 'winAmount'],
        5: ['派奖', 'prize'],
        6: ['有效投注', (d) =>
            <MoreDate cls={'nu'} data={d} cfg={mCfg2} act={!s1} Warp={PrizeNum}>
                <PrizeNum value={d.valid_turnover}/>
            </MoreDate>
        ],
        7: ['返点', 'commission'],
        8: ['活动', (d) =>
            <MoreDate cls={'nu'} data={d} cfg={mCf3} act={!s1} Warp={PrizeNum}>
                <PrizeNum value={d.dividend}/>
            </MoreDate>
        ],
        10: ['净盈', 'dividend'],
        11: ['净亏', 'dividend'],
        9: ['盈亏', (d) => <MoreDate cls={'nu'} data={d} cfg={mCfg1} act={!s1} Warp={PrizeNum}><PrizeNum
            value={(d.profit&&(+d.profit).toFixed(2))}/></MoreDate>],
    }
    const m1 = [12, 2, 6, 5, 8, 9];
    const m2 = [12, 2, 5, 8, 9];
    const cfg = {
        0: [12, 0, 1, 2, 5, 6, 8, 9],
        1: m1,
        2: m1,
        3: m2,
        4: m2,
        5: [12, 10, 11, 6, 8, 9],
        6: m2
    }
    const col = colHandel(cfg[s1], cols, 1, userClick);
    const params = {
        ...times,
        is_need_sum: 1,
        page_size: 15,
        is_direct: +v2,
        username: trim(v1),
        is_team: +isAgentPage(),
    };
    if (s0) {
        params.is_agent = 2 - s0;
    }
    if (s1) params.game_type = ['', 'live', 'sport', 'lottery', 'rng', 'pvp', 'fish'][s1];
    return sheet({
        userListMod: 1,
        fixData(d) {
            if (d) {
                const {data, self} = d;
                const da = (data || []).slice()
                // if (self && isDirect) {
                //     self.username = globalStore.username + '（自己）'
                // }
                // if (self) da.unshift(self)
                return da;
            }
            return []
        },
        cls: 'profitPage',
        title: '团队盈亏',
        cols: col,
        api: 'getTeamBAL',
        params,
        foot(d) {
            const sum = (d || {}).sum || {}
            return <div className={'pro-footer'}>
                <label>{
                    ['全部',
                        '真人',
                        '体育',
                        '彩票',
                        '电子',
                        '棋牌',
                        '捕鱼'][+s1]||''
                }汇总:</label>
                {col.slice(1).map(({name, cell, key}, i) => {
                    if (name === '团队人数') return '';
                    return <div key={i}>
                        <label>{name}:</label>
                        <span>{cell ? cell(sum) : (sum[key] ? parseFloat((+sum[key]).toFixed(2)) : sum[key])}</span>
                    </div>
                })}
            </div>
        },
        filter(q) {
            return <>
                <div className={'ft-b'}>
                    <div className={'r'}>
                        <input type={'text'} value={v1} onChange={change(vv1)} placeholder={'请输入下级账号'}/>
                        <MyDatePicker changeTimeFn={changeTim}/>
                        {user_level>1?null:<Select value={s0} onChange={ss0} items={[
                            [0, '全部下级', '全部'],
                            [1, '代理'],
                            [2, '玩家'],
                        ]}/>}
                        <Select value={s1} onChange={(v) => {
                            ss1(v);
                            q();
                        }} items={[
                            [0, '全部游戏', '全部'],
                            [1, '真人'],
                            [2, '体育'],
                            [3, '彩票'],
                            [4, '电子'],
                            [5, '棋牌'],
                            [6, '捕鱼']
                        ]}/>
                        {user_level>1?null:<CheckBox name={'仅显示直属下级'} onChange={vv2} act={v2}/>}
                        <div className={'btn'} onClick={q}>查询</div>
                        <p>提示：数据每五分钟更新一次</p>
                    </div>
                </div>
            </>
        }
    })
});
