import ReactDOM from 'react-dom';
const defaultRoot  = document.getElementById('root');
/**
 * 將当前的组件放置于指定元素下面  默认body
 * @param [root] 外层元素
 * @param children
 * @return {{children, implementation, containerInfo, $$typeof, key}}
 * @constructor
 */
export const Portals = ({root=()=>defaultRoot,children=[]})=> ReactDOM.createPortal(
    children,
    root()
);