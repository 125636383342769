import React, {useState,useEffect} from 'react';
import {widthDashSheet} from "../../components/sheet";
import './s.scss'
import {change} from "../../components/pwd";
import {fmtErr, tip, trim, uName} from "../../utils";
import {Select} from "../../components/select/select";
import {panel} from "../../components/tabs";
import {PupSetting} from "./p";
import {popSetting} from "../pop/popCenter";
import {query} from "../../api";
import {CheckBox} from "../common/com";


export const SubPage = widthDashSheet((sheet, {
    globalStore: {
        allowed_transfer, has_fund_password, user_level
    }
}, {isDirect, userClick}) => {
    useEffect(()=>{
        query('userSetting')
    },[])
    const [mx, sM] = useState({});
    const [up, sU] = useState({});
    const [v0, vv0] = useState('');
    const [v1, vv1] = useState(0);
    const [v2, vv2] = useState(1);
    const [user, uUser] = useState([]);
    const upRole = i => {
        sM({...mx, [i]: {is_agent: 1}})
    }
    const subClick = (u, i) => () => uUser([u, i]);
    const close = () => uUser([]);
    const params = {
        is_need_sum: 1,
        is_direct: +v2
    };
    if (v1) {
        params.is_agent = 2 - v1;
    }
    if (v0) params.username = v0.toLowerCase();
    return sheet({
        userListMod: 1,
        api: 'getTeamMg',
        params,
        mixData: Object.keys(mx).map((k) => [({id}) => +id === +k, mx[k]]),
        cls: 'subPage',
        tabs: ['下级管理'],
        filter: q => <div className={'r'}>
            <input placeholder={'请输入下级账号'} className={'i-usr'} type={'text'} value={v0} onChange={change(vv0, trim)}/>
            {user_level>1?null:<><Select value={v1} onChange={vv1} items={[
                [0, '全部角色'],
                [1, '代理'],
                [2, '玩家']
            ]}/>  <CheckBox name={'仅显示直属下级'} onChange={vv2} act={v2}/></>}
            <div className={'btn'} onClick={q}>查询</div>
        </div>,
        fixData(d) {
            try {
                return d.data
            } catch (e) {
            }
            return []
        },
        ctxChild: <PupSetting user={user[0]} type={user[1]} close={close}/>,
        foot: (d) => {
            const {numbers: {active_number = 0, user = 0, agent = 0} = {}} = d || {};
            return <div>
                <span>下级用户：<b>{user + agent}</b></span>
                <span>活跃人数：<b>{active_number}</b></span>
                <span>代理：<b>{agent}</b></span>
                <span>玩家：<b>{user}</b></span>
            </div>
        },
        cols: [
            uName(undefined, userClick),
            (user_level>1?null:{
                name: '类型', cell({is_agent}) {
                    return ['玩家', '代理'][is_agent];
                }
            }),
            {name: '团队人数', key: 'team_number'},
            {name: '活跃人数', key: 'active_number'},
            {name: '注册时间', key: 'register_at'},
            {name: '最后登入时间', key: 'signin_at'},
            isDirect && {
                name: '操作', cell(u) {
                    const {is_agent, id, username} = u;
                    return <div className={'opt'}>
                        {/*<div className={'bn'} onClick={subClick(u, 1)}>返点</div>*/}
                        {panel(allowed_transfer, <div onClick={has_fund_password ?
                            subClick(u, 0) : () => {
                                tip.alert({
                                    text: '转账前，请设置资金密码！',
                                    yes: "设置",
                                    no: '取消',
                                    onClose: i => {
                                        if (i) {
                                            popSetting.show(1, subClick(u, 0));
                                        }
                                    }
                                })
                            }} className={'bn'}>转账</div>)}
                        {panel(!is_agent&&user_level<2, <div
                            className={'bn'}
                            onClick={() => {
                                if (up[id]) return;
                                tip.alert({
                                    text: '确定要将用户' + username + '升级为代理吗?',
                                    yes: "升级",
                                    no: '取消',
                                    onClose: i => {
                                        const end = () => {
                                            const uu = {...up};
                                            delete uu[id];
                                            sU(uu);
                                        }
                                        if (i) {
                                            sU({...up, [id]: 1})
                                            query('toAgent', {
                                                user_id: id
                                            }, () => {
                                                tip.alert('用户' + username + '升级代理成功!');
                                                end()
                                                upRole(id)
                                            }, (e) => {
                                                tip.alert(fmtErr(e))
                                                end();
                                            })
                                        }
                                    }
                                })
                            }}>
                            {up[id] ? '升级中...' : '升级'}
                        </div>)}
                    </div>
                }
            },
        ]
    })
});
