export const sscsixingzuxuanzuxuan24 = {
	balls:[
	   [-1,-1,-1,-1,-1,-1,-1,-1,-1,-1]
	],
    num:[
	   [0,1,2,3,4,5,6,7,8,9]
    ],
    views:[
	   [0,1,2,3,4,5,6,7,8,9]
    ],
    titles: [
	   ''
    ],
    chooseType:['全','大','小','奇','偶','清'],
    getLottery:(num,balls)=>{
        let str = ''; 
        for(let i = 0,len_i = balls.length;i < len_i;i++){
            str = i==0?(str):(str+'|')
		    for(let j = 0,len_j = balls[i].length;j < len_j;j++){
			    if(balls[i][j] > 0){
                   str += num[i][j];
				}
		    }
    	}
	    return str;
    },
    getNum:(balls)=>{
        let len_all = 0,num = 0;
        for(let i=0,len=balls.length;i<len;i++){
            for(let j=0,len_j = balls[i].length;j<len_j;j++){
                if(balls[i][j]>-1){
                    len_all++;
                }
            }
        }
        if(len_all>=4){
            num = len_all*(len_all-1)*(len_all-2)*(len_all-3)/24;
        }       
        return num;
    },
    getView: (views,balls)=>{
        let str = ''; 
        for(let i = 0,len_i = balls.length;i < len_i;i++){
            str = i==0?(str):(str+'|')
		    for(let j = 0,len_j = balls[i].length;j < len_j;j++){
			    if(balls[i][j] > 0){
                    str += views[i][j];
				}
		    }
    	}
	    return str;
    }
}
