export const p35qiansanzhixuanhezhi =  {
	balls:[
	    [-1,-1,-1,-1,-1,-1,-1,-1,-1,-1,-1,-1,-1,-1,-1,-1,-1,-1,-1,-1,-1,-1,-1,-1,-1,-1,-1,-1]
	],
    num:[
	    [0,1,2,3,4,5,6,7,8,9,10,11,12,13,14,15,16,17,18,19,20,21,22,23,24,25,26,27]
    ],
    views:[
	    [0,1,2,3,4,5,6,7,8,9,10,11,12,13,14,15,16,17,18,19,20,21,22,23,24,25,26,27]
    ],
    titles: [
        '直选和值'
    ],
    getLottery:(num,balls)=>{
        let str = ""; 
        for(let i = 0;i < balls.length;i++){
		    for(let l = 0;l < balls[i].length;l++){
			    if(balls[i][l] > 0){
				    str +=str?('|'+num[i][l]):num[i][l];
				}
		    }
    	}
	    return str;
    },
    getNum:(balls)=>{
        let num = 0,arr = [1,3,6,10,15,21,28,36,45,55,63,69,73,75,75];
        for(let i=0,len=balls.length;i<len;i++){
            for(let j=0,len_j=balls[i].length;j<len_j;j++){
                if(balls[i][j]>0){
                    num+= j>13?arr[27-j]:arr[j];
                }
            }
        }
        return num;
    },
    getView: (views,balls)=>{
        let str = ""; 
        for(let i = 0;i < balls.length;i++){
            for(let l = 0;l < balls[i].length;l++){
                if(balls[i][l] > 0){
                    str +=str?('|'+views[i][l]):views[i][l];
                }
            }
        }
        return str;
    }
}
