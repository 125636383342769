export const k3k3k3ertonghao = {
    balls:[
       [-1,-1,-1,-1,-1],
       [-1,-1,-1,-1,-1],
       [-1,-1,-1,-1,-1],
       [-1,-1,-1,-1,-1],
       [-1,-1,-1,-1,-1],
       [-1,-1,-1,-1,-1]
    ],
    num: [
       [112,113,114,115,116],
       [122,223,224,225,226],
       [133,233,334,335,336],
       [144,244,344,445,446],
       [155,255,355,455,556],
       [166,266,366,466,566]
    ],
    views: [
       [112,113,114,115,116],
       [122,223,224,225,226],
       [133,233,334,335,336],
       [144,244,344,445,446],
       [155,255,355,455,556],
       [166,266,366,466,566]
    ],
    titles: [
       '同号(11)',
       '同号(22)',
       '同号(33)',
       '同号(44)',
       '同号(55)',
       '同号(66)'
    ],
    chooseType:['全','清'],
    getLottery:(num,balls)=>{
        let view = ""; 
        for(let i = 0;i < balls.length;i++){
            for(let l = 0;l < balls[i].length;l++){
                if(balls[i][l] > 0){
                    view +=num[i][l]+"|"
                }
            }
        }
        return view.substring(0,view.length-1);
    },
    getNum:(balls)=>{
        let num = 0,arr = balls;
        for(let i=0,len=arr.length;i<len;i++){
            for(let j=0,len_j=arr[i].length;j<len_j;j++){
                if(arr[i][j]>0){
                    num++;
                }
            }
        }
        return num;
    },
    getView: (views,balls)=>{
        let view = ""; 
        for(let i = 0;i < balls.length;i++){
            for(let l = 0;l < balls[i].length;l++){
                if(balls[i][l] > 0){
                    view +=views[i][l]+"|"
                } 
            }
        }
        return view.substring(0,view.length-1);
    }
}

