import './g.scss';
import React, {useEffect, useMemo, useState} from 'react';
import {widthDashBoard} from "../../components/dashboard/Dashboard";
import {DashTitle, NoDate} from "../common/com";
import {panel, Tabs} from "../../components/tabs";
import {trim} from "../../utils";
import {change} from "../../components/pwd";
import {useQuery} from "../../hooks";
import {Paging} from "../../components/paging/Paging";
import {Loading} from "../../components/loading";
import {history} from "../../store";
import {query} from "../../api";

const getSender = (sender, is_user) => {
    let p = '';
    let c = 0;
    let e = '平台';
    if (!is_user) {
        if (/system/i.test(sender)) {
            c = 1;
            p = '活动'
            e = '平台'
        } else {
            c = 3;
            p = '系统';
            e = '管理员';
        }
    } else if (sender) {
        e = sender;
        c = 2;
        p = '个人'
    }
    return [p, c, e];
}

const Detail = ({d, close, id}) => {
    const [q, ld, da] = useQuery('article');
    useEffect(() => {
        if (id <= 0) return;
        q({
            id: id
        })
    }, [id])
    if (!d && id <= 0) return null;
    const dd = id ? da : d;
    const {title = '', content = '', created_at, sender, is_user} = dd || {};
    const [, , e] = getSender(sender, is_user);
    return <div className={'ref'}>
        <span onClick={close}>{'<'}返回</span>
        <h1>{title}</h1>
        <p><span>发件人:{e}</span><span>时间：{created_at}</span></p>
        <div className={'ctx'} ref={a => {
            if (a) a.innerHTML = content
        }}/>
        <Loading act={ld}>正在获取内容</Loading>
    </div>
}

const A = widthDashBoard(({match: {params: {id}}}, {globalStore}) => {
    const [r, rr] = useState([])
    const [getArt, artLd, arts] = useQuery('article')
    const [getMsg, mLd, msg] = useQuery('webMsg')
    const [post, pLd] = useQuery('suggestions')
    const [t, tt] = useState(1);
    const [v0, vv0] = useState('')
    const [v1, vv1] = useState('')
    const [d, dd] = useState(null);
    const [a, aa] = useState(-1);
    const [page, pp] = useState(null);
    const loading = t !== 2 && (t ? mLd : artLd);
    const articles = useMemo(() => {
        let da = (t ? msg : arts);
        if (t) {
            if (da) {
                (da.data || []).forEach(d => {
                    d.title = d.msg_title;
                })
            }
        }
        return (da && da.data) || [];
    }, [arts, msg, t]);
    const total = useMemo(() => {
        let da = t ? msg : arts;
        return (da && da.last_page) || 0
    }, [arts, msg, t])
    const tl = useMemo(() => {
        let da = t ? msg : arts;
        return (da && da.total) || 0
    }, [arts, msg, t])
    useEffect(() => {
        pp(1);
        dd(null);
    }, [t, id])
    useEffect(() => {
        if (a) return;
        switch (t) {
            case  0:
                return getArt({
                    paginate: 1,
                    count: 10,
                    page
                })
            case 1:
            case 2:
                return getMsg({
                    paginate: 1,
                    count: 10,
                    page
                })
            default:
                return
        }
    }, [t, page, a]);
    useEffect(() => {
        if (!id) {
            aa(0);
        } else aa(+id);
    }, [id])
    useEffect(() => {
        if (d) {
            const {id, status} = d;
            if (id) {
                if (r.includes(id) || status) return;
                rr(r.concat(id))
                if (globalStore.unread) globalStore.unread--;
                query('msgView', {id})
            }
        }
    }, [d])
    const isRead = ({id, is_readed, status}) => r.includes(id) || is_readed || status;
    return <div className={'x-sheet mgPage'}>
        <div className={'x-st-h'}>
            <DashTitle title={'站内信'}/>
        </div>
        <div className={'x-st-ti'}>
            <Tabs change={tt} act={t}>{o => <div className={'tbs'}>
                <div {...o(1)}>{'站内消息'}</div>
                <div {...o(0)}>{'平台公告'}</div>
                <div {...o(2)}>{'提建议'}</div>
            </div>}</Tabs>
        </div>
        <div className={'x-ss'}>
            <div className={'x-tba'}>
                {panel(t !== 2, tl ? <div className={'art'}>
                        <div className={'x-ss'}>
                            {
                                articles.map((o, i) => {
                                    const {title, summary, created_at, sender, is_user} = o;
                                    const [p, c, e] = getSender(sender, is_user);
                                    return <div key={i} className={'r' + (isRead(o) ? ' rd' : '')}
                                                onClick={() => dd(o)}>
                                        <div>
                                            {c ? <label className={'tg' + c}>{p}消息</label> : null}
                                            <h1>{title}</h1>
                                            <span>{summary}</span>
                                        </div>
                                        {sender ? <span className={'sd'}>{e}</span> : null}
                                        <span>{created_at}</span>
                                    </div>
                                })
                            }
                        </div>
                        <Paging data={{totalPage: total, cPage: page, handleBtnClick: pp}}/>
                    </div>
                    : <NoDate>暂无信息</NoDate>)}
                <Detail d={d} id={a} close={() => {
                    dd(null);
                    aa(0);
                    if (id) {
                        history.replace('/msg')
                    }
                }}/>
                {panel(t !== 2, <Loading act={loading}>正在加载</Loading>)}
                {panel(t === 2, <div className={'send-msg'}>
                    <div className={'r'}>
                        <label>标题：</label>
                        <input
                            placeholder={'请输入您要反馈的要点'}
                            value={v0} onChange={change(vv0, trim)}/>
                    </div>
                    <div className={'r a'}>
                        <label>内容：</label>
                        <textarea
                            placeholder={'请输入内容'}
                            value={v1} onChange={change(vv1, trim)}/>
                    </div>
                    <div className={'btn' + (!v1 || !v0 ? ' dis' : '')}
                         onClick={pLd ? null : () => post({comment: v0 + ':' + v1})}
                    >{pLd ? '发送中' : '发送'}</div>
                </div>)}
            </div>
        </div>
    </div>
})

export const MgPage = props => <A {...props} cls={'xt'}/>
