import React, {useEffect} from 'react';
import {Route, Switch, Router, Redirect} from 'react-router-dom';
import {routes as rs} from './routes';
import {history, useAuth, widthStore} from "../store";
import {P404} from "../pages/404";
import {Confirm} from "../components/confirm/confirm";
import {useQuery} from "../hooks";
import {NoticeList} from "../components/notification/notice";
import {PopNotice} from "../components/pup-notice/notice";
import {PopWallet} from "../pages/pop/popWallet";
import {PopChPwd} from "../pages/pop/popCenter";
import {PopAva} from "../pages/pop/ava";
import {ServiceWin} from "../pages/common/com";
import {tip, WS} from "../utils";
import {PopPromotion} from "../pages/promotion";

let flag = '';

const Cp = React.memo(({ps, cpm, c}) => {
    const [pass, next, hide] = useAuth(c);
    const Rt = cpm.component ? cpm.component : cpm;
    useEffect(() => {
        if (flag !== c) {
            flag = c;
            document.getElementById('root').scrollTop = 0;
        }
        next()
    }, [next]);
    return pass && !hide ? <Rt {...{...ps, history}}/> : <Redirect to={window.lastPage}/>
});

const Init = widthStore(({walletStore, globalStore}) => {
    const {token} = globalStore;
    const [queryUS] = useQuery('userSetting');
    const [queryBk] = useQuery('myBankCard');
    const [gb] = useQuery('balance');
    const [queryLottery] = useQuery('getLottery');
    const [thirdProduct] = useQuery('thirdProduct');
    const [init] = useQuery('init');
    const [tps] = useQuery('aType');
    useEffect(() => {
        globalStore.captchaCode = 0;
        globalStore.capLd = 0;
        queryLottery();
        init();
        tps();
        thirdProduct();
    }, []);
    useEffect(() => {
        if (token) {
            WS.connect(token)
                .subscribe('withdraw', function (dd, ex) {
                    const {request_time, amount} = dd || ex;
                    tip.popup({
                        key: request_time + amount,
                        data: `您好，您在${request_time}申请的提款金额${amount}已成功`,
                        title: '实时消息'
                    })
                })
                .subscribe('balance', (v) => {
                    if (v) {
                        if ('string' === typeof v) {
                            v = v.replace(/,/g, '')
                        }
                        if (isNaN(+v)) {
                            return
                        }
                        const m = walletStore.bls['main'];
                        if (m&&!globalStore.is_seamless_wallet) {
                            globalStore.available = m.balance = +v;
                            m.loading = 0;
                        }else walletStore.query('main');
                    }
                });
            queryUS();
            queryBk();
            walletStore.load().query();
            const t = setInterval(() => {
                gb()
            }, 3e4);
            return () => clearInterval(t);
        } else WS.close();
    }, [token]);
    return null
})

export const Root = () => {
    const adapter = (c, i) => {
        const Cmp = rs[c];
        return <Route
            key={'r-' + i}
            path={'/' + c}
            exact={Cmp && !!Cmp.exact}
            component={ps => <Cp ps={ps} c={c} cpm={Cmp}/>}
        />
    };
    const routes = Object.keys(rs).map(adapter);
    return <Router history={history}>
        <Switch>
            {routes}
            <Route component={P404}/>
        </Switch>
        <Confirm/>
        <NoticeList/>
        <PopNotice/>
        <PopChPwd/>
        <PopPromotion/>
        <PopWallet/>
        <PopAva/>
        <ServiceWin/>
        <Init/>
    </Router>
};
