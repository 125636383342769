import React, {useMemo} from 'react';
import './paging.scss';

export const Paging = ({data: {totalPage=1, cPage=1, handleBtnClick}={}}) => {
    function fixPage(v) {
        if (v > totalPage) v = totalPage;
        if (v < 1) v = 1;
        return v;
    }

    function jump(v) {
        return () => {
            v = fixPage(v);
            if (v !== cPage) {
                handleBtnClick(v)
            }
        }
    }

    const hasPrev = cPage > 1;
    const hasNext = cPage < totalPage;
    const arr = useMemo(() => {
        let lis = [1];
        const sub = [];
        const p = cPage % 2 + 1;
        let s = Math.max(2, cPage - p);
        const e = Math.min(totalPage, s + 3);
        s = Math.max(2, e - 3);
        for (let i = s; i <= e; i++) {
            sub.push(i);
        }
        if (sub[0] - 1 > 1) {
            lis.push(0)
        }
        const sLast = sub[sub.length - 1];
        if (sLast < totalPage) {
            if (totalPage - sLast > 2) sub.push(0);
            sub.push(totalPage);
        }
        lis = lis.concat(sub);
        return lis
    }, [cPage, totalPage]);

    return <div className={'x-pg'}>
        <i/>
        <span className={'pg-p pg-st' + (hasPrev ? '' : ' dis')} onClick={jump(cPage - 1)}><span>{'<'}</span></span>
        {arr.map((k, i) => {
            const key = k+''+i;
            if (k) return <span className={'pg-p' + (cPage === k ? ' act' : '')} key={key} onClick={jump(k)}><span>{k}</span></span>
            else return <span className={'pg-m'} key={key}>...</span>
        })}
        <span className={'pg-p pg-en' + (hasNext ? '' : ' dis')} onClick={jump(cPage + 1)}><span>{'>'}</span></span>
    </div>
};
