import React, {useEffect} from 'react';
import {NewHead, Foot, Foot2} from './com'

export const Page = ({children, head=null, cls}) => {
    useEffect(() => {
        (document.scrollingElement || document.body).scrollTop = 0
    }, []);
    return <div className={'full-page ' + (cls || '')}>
        <NewHead/>
        {head}
        <div className={'full bg0'}>
            {children}
        </div>
        <Foot2/>
        <Foot/>
    </div>
};
