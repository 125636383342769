import {widthDashBoard} from "../../components/dashboard/Dashboard";
import {Tabs} from "../../components/tabs";
import React, {useEffect, useMemo, useState} from "react";
import './i.scss'
import {DashTitle, PrizeNum} from "../common/com";
import {MyDatePicker} from "../../components/dashboard/gameRecord/MyDatePicker";
import {Table} from "../../components/table/table";
import {useQuery} from "../../hooks";
import {Loading} from "../../components/loading";
import {Paging} from "../../components/paging/Paging";

const Card = ({cls, label, children, loading}) => <div className={`ac ${cls}`}>
    <Loading act={loading}/>
    <div className={'lb'}><span>{label}</span></div>
    <div className={'ct'}>
        {children}
    </div>
</div>


export const Analysis = widthDashBoard(() => {
    const [q0, l0, d0] = useQuery('opData')
    const [q1, l1, d1] = useQuery('regList')
    const [q2, l2, d2] = useQuery('disposeList')
    const [q3, l3, d3] = useQuery('firstDispose')
    const [q4, l4, d4] = useQuery('summary')
    const [t, tt] = useState(0);

    useEffect(() => pp(1), [t])
    const [p, pp] = useState(1)
    const {last_page: t0 = 0, data: da0} = d1 || {};
    const {last_page: t1 = 0, data: da1} = d2 || {};
    const {last_page: t2 = 0, data: da2} = d3 || {};
    const [total, ld, da] = [
        [t0, t1, t2][t],
        [l1, l2, l3][t],
        [da0, da1, da2][t]
    ]

    const [oa, ob] = useMemo(() => {
        if (d4) {
            const l = Math.floor((d4.length + 1) / 2)
            return [d4.slice(0, l), d4.slice(l)]
        }
        return [[], []]
    }, [d4])

    const rs = [
        {key: 'product', name: '游戏名称'},
        {key: 'validBetAmount', name: '有效投注'},
        {cell: ({netPnl})=><PrizeNum simple value={netPnl}/>,name: '输赢'}
    ]

    const rs1 = [
        [
            {key: 'username', name: '会员账号'},
            {cell:({url})=>url||'人工开户', name: '注册来源'},
            {key: 'register_at', name: '注册时间'},
        ],
        [
            {key: 'username', name: '会员账号'},
            {key: 'amount', name: '充值金额'},
            {key: 'created_at', name: '充值时间'},
        ],
        [
            {key: 'username', name: '会员账号'},
            {key: 'amount', name: '首存金额'},
            {key: 'created_at', name: '首存时间'},
        ],
    ][t]

    useEffect(() => {
        [q1, q2, q3][t]({
            page: p
        })
    }, [t, p])

    const pk = {
        quick: [-30, -7, 2, 1],
        init: 0,
        oc: 'crd'
    }
    return <div className={'anPage x-sheet '}>
        <div className={'x-st-ti'}>
            <i/>
            <DashTitle title={'数据概览'}/>
        </div>
        <Card cls={'a0'} label={'运营数据'} loading={l0}>
            <MyDatePicker changeTimeFn={q0} {...pk}/>
            <div className={'cs'}>
                {[
                    [
                        ['新注册会员', 'register'],
                        ['下注会员数', 'turnover_number'],
                        ['首存会员数', 'first_deposit_number'],
                        ['充值会员数', 'deposit_number'],
                    ],
                    [
                        ['总盈利', ({profit}) => <PrizeNum simple value={profit}/>],
                        ['下注金额', 'turnover'],
                        ['首存金额', 'first_deposit'],
                        ['充值金额', 'deposit'],
                    ],
                ].map((a, i) => <div className={'ro'} key={i}>
                    {a.map(([n, k]) => <div key={k} className={'ce'}>
                        <h5>{n}</h5>
                        <p>{d0 && (typeof k === 'function' ? k(d0) : d0[k]) || 0}</p>
                    </div>)}
                </div>)}
            </div>
        </Card>

        <Card cls={'a1'} label={'今日播报'} loading={ld}>
            <Tabs act={t} change={tt}>{o => <div className={'tt'}>
                {['新会员注册', '会员充值', '会员首存']
                    .map((t, i) => <div {...o(i)}>{t}</div>)}
                <i/>
            </div>}</Tabs>
            <div className={'t'}>
                <Table
                    className={'x-table'}
                    data={da || []}
                    row={rs1}/>
                <Paging data={{totalPage: total, page: p, handleBtnClick: pp}}/>
            </div>
        </Card>
        <Card cls={'a2'} label={'游戏汇总'} loading={l4}>
            <MyDatePicker changeTimeFn={q4} {...pk} />
            <div className={'ts'}>
                <div className={'t'}><Table className={'x-table'} data={oa} row={rs}/></div>
                <div className={'t'}><Table className={'x-table'} data={ob} row={rs}/></div>
            </div>
        </Card>
    </div>
}, {cls: 'xt',out:'trp'})