export const k3k3k3dantiaoyishai ={
	balls:[
	   [-1,-1,-1,-1,-1,-1]
	],
    num:[
        [1,2,3,4,5,6]
    ],
    views:[
        [1,2,3,4,5,6]
    ],
    titles: [
	  '单挑一骰'
    ],
    getNum:(balls)=>{
        let num = 0,arr = balls[0];
        for(let i=0,len=arr.length;i<len;i++){
            if(arr[i]>0){
                num++;
            }
        }
        return num;
    }
}
export const k3k3k3caibuchuhaoma ={
    ...k3k3k3dantiaoyishai,
    titles: [
        '猜不出号'
    ]
};
export const k3k3k3kuadu = {
    ...k3k3k3dantiaoyishai,
    num:[
        [0,1,2,3,4,5]
    ],
    views:[
        [0,1,2,3,4,5]
    ],
    titles: [
        '跨度'
    ],
};