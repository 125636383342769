export const cityStore = {
    cities(pid) {
        const o = cityStore.province.find(({id}) => (id+'') === (pid+''));
        return o && o.children || []
    },
    province: [{
        id: 1,
        name: "北京",
        children: [{
            id: 35,
            name: "东城区"
        }, {
            id: 36,
            name: "西城区"
        }, {
            id: 38,
            name: "朝阳区"
        }, {
            id: 39,
            name: "海淀区"
        }, {
            id: 40,
            name: "丰台区"
        }, {
            id: 41,
            name: "石景山区"
        }, {
            id: 42,
            name: "门头沟区"
        }, {
            id: 43,
            name: "房山区"
        }, {
            id: 44,
            name: "通州区"
        }, {
            id: 45,
            name: "顺义区"
        }, {
            id: 46,
            name: "昌平区"
        }, {
            id: 47,
            name: "大兴区"
        }, {
            id: 48,
            name: "平谷区"
        }, {
            id: 49,
            name: "怀柔区"
        }, {
            id: 50,
            name: "密云县"
        }, {
            id: 51,
            name: "延庆县"
        }]
    }, {
        id: 2,
        name: "上海",
        children: [{
            id: 140,
            name: "黄浦区"
        }, {
            id: 141,
            name: "卢湾区"
        }, {
            id: 142,
            name: "徐汇区"
        }, {
            id: 143,
            name: "长宁区"
        }, {
            id: 144,
            name: "静安区"
        }, {
            id: 145,
            name: "普陀区"
        }, {
            id: 146,
            name: "闸北区"
        }, {
            id: 147,
            name: "虹口区"
        }, {
            id: 148,
            name: "杨浦区"
        }, {
            id: 149,
            name: "宝山区"
        }, {
            id: 150,
            name: "闵行区"
        }, {
            id: 151,
            name: "嘉定区"
        }, {
            id: 152,
            name: "浦东新区"
        }, {
            id: 153,
            name: "松江区"
        }, {
            id: 154,
            name: "金山区"
        }, {
            id: 155,
            name: "青浦区"
        }, {
            id: 156,
            name: "南汇区"
        }, {
            id: 157,
            name: "奉贤区"
        }, {
            id: 158,
            name: "崇明县"
        }]
    }, {
        id: 3,
        name: "天津",
        children: [{
            id: 52,
            name: "和平区"
        }, {
            id: 53,
            name: "河东区"
        }, {
            id: 54,
            name: "河西区"
        }, {
            id: 55,
            name: "南开区"
        }, {
            id: 56,
            name: "河北区"
        }, {
            id: 57,
            name: "红桥区"
        }, {
            id: 58,
            name: "塘沽区"
        }, {
            id: 59,
            name: "汉沽区"
        }, {
            id: 60,
            name: "大港区"
        }, {
            id: 61,
            name: "东丽区"
        }, {
            id: 62,
            name: "西青区"
        }, {
            id: 63,
            name: "北辰区"
        }, {
            id: 64,
            name: "津南区"
        }, {
            id: 65,
            name: "武清区"
        }, {
            id: 66,
            name: "静海县"
        }, {
            id: 67,
            name: "宁河县"
        }, {
            id: 68,
            name: "宝坻区"
        }, {
            id: 69,
            name: "蓟县"
        }]
    }, {
        id: 4,
        name: "重庆",
        children: [{
            id: 337,
            name: "渝中区"
        }, {
            id: 338,
            name: "大渡口"
        }, {
            id: 339,
            name: "江北区"
        }, {
            id: 340,
            name: "沙坪坝"
        }, {
            id: 341,
            name: "九龙坡"
        }, {
            id: 342,
            name: "南岸区"
        }, {
            id: 343,
            name: "北碚区"
        }, {
            id: 344,
            name: "万盛区"
        }, {
            id: 345,
            name: "双桥区"
        }, {
            id: 346,
            name: "渝北区"
        }, {
            id: 347,
            name: "巴南区"
        }, {
            id: 348,
            name: "万州区"
        }, {
            id: 349,
            name: "涪陵区"
        }, {
            id: 350,
            name: "黔江区"
        }, {
            id: 351,
            name: "永川区"
        }, {
            id: 352,
            name: "合川区"
        }, {
            id: 353,
            name: "江津区"
        }, {
            id: 354,
            name: "南川区"
        }, {
            id: 355,
            name: "长寿县"
        }, {
            id: 356,
            name: "綦江县"
        }, {
            id: 357,
            name: "潼南县"
        }, {
            id: 358,
            name: "荣昌县"
        }, {
            id: 359,
            name: "璧山县"
        }, {
            id: 360,
            name: "大足县"
        }, {
            id: 361,
            name: "铜梁县"
        }, {
            id: 362,
            name: "梁平县"
        }, {
            id: 363,
            name: "城口县"
        }, {
            id: 364,
            name: "垫江县"
        }, {
            id: 365,
            name: "武隆县"
        }, {
            id: 366,
            name: "丰都县"
        }, {
            id: 367,
            name: "奉节县"
        }, {
            id: 368,
            name: "开县"
        }, {
            id: 369,
            name: "云阳县"
        }, {
            id: 370,
            name: "忠县"
        }, {
            id: 371,
            name: "巫溪县"
        }, {
            id: 372,
            name: "巫山县"
        }, {
            id: 373,
            name: "石柱县"
        }, {
            id: 374,
            name: "秀山县"
        }, {
            id: 375,
            name: "酉阳县"
        }, {
            id: 376,
            name: "彭水县"
        }, {
            id: 489,
            name: "高新区"
        }]
    }, {
        id: 5,
        name: "广东",
        children: [{
            id: 286,
            name: "广州"
        }, {
            id: 287,
            name: "深圳"
        }, {
            id: 288,
            name: "珠海"
        }, {
            id: 289,
            name: "汕头"
        }, {
            id: 290,
            name: "韶关"
        }, {
            id: 291,
            name: "河源"
        }, {
            id: 292,
            name: "梅州"
        }, {
            id: 293,
            name: "惠州"
        }, {
            id: 294,
            name: "汕尾"
        }, {
            id: 295,
            name: "东莞"
        }, {
            id: 296,
            name: "中山"
        }, {
            id: 297,
            name: "佛山"
        }, {
            id: 298,
            name: "阳江"
        }, {
            id: 299,
            name: "湛江"
        }, {
            id: 300,
            name: "茂名"
        }, {
            id: 301,
            name: "肇庆"
        }, {
            id: 302,
            name: "清远"
        }, {
            id: 303,
            name: "潮州"
        }, {
            id: 304,
            name: "揭阳"
        }, {
            id: 305,
            name: "云浮"
        }, {
            id: 480,
            name: "江门"
        }]
    }, {
        id: 6,
        name: "广西",
        children: [{
            id: 306,
            name: "南宁"
        }, {
            id: 307,
            name: "柳州"
        }, {
            id: 308,
            name: "桂林"
        }, {
            id: 309,
            name: "梧州市"
        }, {
            id: 310,
            name: "北海"
        }, {
            id: 311,
            name: "防城港"
        }, {
            id: 312,
            name: "钦州"
        }, {
            id: 313,
            name: "贵港"
        }, {
            id: 314,
            name: "玉林"
        }, {
            id: 315,
            name: "贺州"
        }, {
            id: 316,
            name: "百色"
        }, {
            id: 317,
            name: "河池"
        }, {
            id: 487,
            name: "南宁地区"
        }, {
            id: 488,
            name: "柳州地区"
        }]
    }, {
        id: 7,
        name: "福建",
        children: [{
            id: 200,
            name: "福州"
        }, {
            id: 201,
            name: "厦门"
        }, {
            id: 202,
            name: "三明"
        }, {
            id: 203,
            name: "莆田"
        }, {
            id: 204,
            name: "泉州"
        }, {
            id: 205,
            name: "漳州"
        }, {
            id: 206,
            name: "南平"
        }, {
            id: 207,
            name: "龙岩"
        }, {
            id: 208,
            name: "宁德"
        }]
    }, {
        id: 8,
        name: "浙江",
        children: [{
            id: 172,
            name: "杭州"
        }, {
            id: 173,
            name: "宁波"
        }, {
            id: 174,
            name: "温州"
        }, {
            id: 175,
            name: "嘉兴"
        }, {
            id: 176,
            name: "湖州"
        }, {
            id: 177,
            name: "绍兴"
        }, {
            id: 178,
            name: "金华"
        }, {
            id: 179,
            name: "衢州"
        }, {
            id: 180,
            name: "舟山"
        }, {
            id: 181,
            name: "台州"
        }, {
            id: 182,
            name: "丽水"
        }]
    }, {
        id: 9,
        name: "江苏",
        children: [{
            id: 159,
            name: "南京"
        }, {
            id: 160,
            name: "徐州"
        }, {
            id: 161,
            name: "连云港"
        }, {
            id: 162,
            name: "淮安"
        }, {
            id: 163,
            name: "宿迁"
        }, {
            id: 164,
            name: "盐城"
        }, {
            id: 165,
            name: "扬州"
        }, {
            id: 166,
            name: "泰州"
        }, {
            id: 167,
            name: "南通"
        }, {
            id: 168,
            name: "镇江"
        }, {
            id: 169,
            name: "常州"
        }, {
            id: 170,
            name: "无锡"
        }, {
            id: 171,
            name: "苏州"
        }]
    }, {
        id: 10,
        name: "山东",
        children: [{
            id: 220,
            name: "济南"
        }, {
            id: 221,
            name: "青岛"
        }, {
            id: 222,
            name: "淄博"
        }, {
            id: 223,
            name: "枣庄"
        }, {
            id: 224,
            name: "东营"
        }, {
            id: 225,
            name: "潍坊"
        }, {
            id: 226,
            name: "烟台"
        }, {
            id: 227,
            name: "威海"
        }, {
            id: 228,
            name: "济宁"
        }, {
            id: 229,
            name: "泰安"
        }, {
            id: 230,
            name: "日照"
        }, {
            id: 231,
            name: "莱芜"
        }, {
            id: 232,
            name: "德州"
        }, {
            id: 233,
            name: "临沂"
        }, {
            id: 234,
            name: "聊城"
        }, {
            id: 235,
            name: "菏泽"
        }, {
            id: 236,
            name: "滨州"
        }]
    }, {
        id: 11,
        name: "山西",
        children: [{
            id: 81,
            name: "太原"
        }, {
            id: 82,
            name: "大同"
        }, {
            id: 83,
            name: "阳泉"
        }, {
            id: 84,
            name: "长治"
        }, {
            id: 85,
            name: "晋城"
        }, {
            id: 86,
            name: "朔州"
        }, {
            id: 87,
            name: "晋中"
        }, {
            id: 88,
            name: "忻州"
        }, {
            id: 89,
            name: "临汾"
        }, {
            id: 90,
            name: "运城"
        }, {
            id: 91,
            name: "吕梁地区"
        }]
    }, {
        id: 12,
        name: "辽宁",
        children: [{
            id: 104,
            name: "沈阳"
        }, {
            id: 105,
            name: "大连"
        }, {
            id: 106,
            name: "鞍山"
        }, {
            id: 107,
            name: "抚顺"
        }, {
            id: 108,
            name: "本溪"
        }, {
            id: 109,
            name: "丹东"
        }, {
            id: 110,
            name: "锦州"
        }, {
            id: 111,
            name: "葫芦岛"
        }, {
            id: 112,
            name: "营口"
        }, {
            id: 113,
            name: "盘锦"
        }, {
            id: 114,
            name: "阜新"
        }, {
            id: 115,
            name: "辽阳"
        }, {
            id: 116,
            name: "铁岭"
        }, {
            id: 117,
            name: "朝阳"
        }]
    }, {
        id: 13,
        name: "吉林",
        children: [{
            id: 118,
            name: "长春"
        }, {
            id: 119,
            name: "吉林市"
        }, {
            id: 120,
            name: "四平"
        }, {
            id: 121,
            name: "辽源"
        }, {
            id: 122,
            name: "通化"
        }, {
            id: 123,
            name: "白山"
        }, {
            id: 124,
            name: "松原"
        }, {
            id: 125,
            name: "白城"
        }, {
            id: 126,
            name: "延边自治州"
        }]
    }, {
        id: 14,
        name: "黑龙江",
        children: [{
            id: 127,
            name: "哈尔滨"
        }, {
            id: 128,
            name: "齐齐哈尔"
        }, {
            id: 129,
            name: "鹤岗"
        }, {
            id: 130,
            name: "双鸭山"
        }, {
            id: 131,
            name: "鸡西"
        }, {
            id: 132,
            name: "大庆"
        }, {
            id: 133,
            name: "伊春"
        }, {
            id: 134,
            name: "牡丹江"
        }, {
            id: 135,
            name: "佳木斯"
        }, {
            id: 136,
            name: "七台河"
        }, {
            id: 137,
            name: "黑河"
        }, {
            id: 138,
            name: "绥化"
        }, {
            id: 139,
            name: "大兴安岭地区"
        }]
    }, {
        id: 15,
        name: "河北",
        children: [{
            id: 70,
            name: "石家庄"
        }, {
            id: 71,
            name: "唐山"
        }, {
            id: 72,
            name: "秦皇岛"
        }, {
            id: 73,
            name: "邯郸"
        }, {
            id: 74,
            name: "邢台"
        }, {
            id: 75,
            name: "保定"
        }, {
            id: 76,
            name: "张家口"
        }, {
            id: 77,
            name: "承德"
        }, {
            id: 78,
            name: "沧州"
        }, {
            id: 79,
            name: "廊坊"
        }, {
            id: 80,
            name: "衡水"
        }]
    }, {
        id: 16,
        name: "河南",
        children: [{
            id: 237,
            name: "郑州"
        }, {
            id: 238,
            name: "开封"
        }, {
            id: 239,
            name: "洛阳"
        }, {
            id: 240,
            name: "平顶山"
        }, {
            id: 241,
            name: "焦作"
        }, {
            id: 242,
            name: "鹤壁"
        }, {
            id: 243,
            name: "新乡"
        }, {
            id: 244,
            name: "安阳"
        }, {
            id: 245,
            name: "濮阳"
        }, {
            id: 246,
            name: "许昌"
        }, {
            id: 247,
            name: "漯河"
        }, {
            id: 248,
            name: "三门峡"
        }, {
            id: 249,
            name: "南阳"
        }, {
            id: 250,
            name: "商丘"
        }, {
            id: 251,
            name: "信阳"
        }, {
            id: 252,
            name: "周口"
        }, {
            id: 253,
            name: "驻马店"
        }, {
            id: 254,
            name: "济源"
        }]
    }, {
        id: 17,
        name: "四川",
        children: [{
            id: 377,
            name: "成都"
        }, {
            id: 378,
            name: "自贡"
        }, {
            id: 379,
            name: "攀枝花"
        }, {
            id: 380,
            name: "泸州"
        }, {
            id: 381,
            name: "德阳"
        }, {
            id: 382,
            name: "绵阳"
        }, {
            id: 383,
            name: "广元"
        }, {
            id: 384,
            name: "遂宁"
        }, {
            id: 385,
            name: "内江"
        }, {
            id: 386,
            name: "乐山"
        }, {
            id: 387,
            name: "南充"
        }, {
            id: 388,
            name: "宜宾"
        }, {
            id: 389,
            name: "广安"
        }, {
            id: 390,
            name: "达州"
        }, {
            id: 391,
            name: "巴中"
        }, {
            id: 392,
            name: "雅安"
        }, {
            id: 393,
            name: "眉山"
        }, {
            id: 394,
            name: "资阳"
        }, {
            id: 395,
            name: "阿坝自治州"
        }, {
            id: 396,
            name: "甘孜自治州"
        }, {
            id: 397,
            name: "凉山自治州"
        }]
    }, {
        id: 18,
        name: "陕西",
        children: [{
            id: 430,
            name: "西安"
        }, {
            id: 431,
            name: "铜川"
        }, {
            id: 432,
            name: "宝鸡"
        }, {
            id: 433,
            name: "咸阳"
        }, {
            id: 434,
            name: "渭南"
        }, {
            id: 435,
            name: "延安"
        }, {
            id: 436,
            name: "汉中"
        }, {
            id: 437,
            name: "榆林"
        }, {
            id: 438,
            name: "安康"
        }, {
            id: 439,
            name: "商洛地区"
        }]
    }, {
        id: 19,
        name: "湖北",
        children: [{
            id: 255,
            name: "武汉"
        }, {
            id: 256,
            name: "黄石"
        }, {
            id: 257,
            name: "襄樊"
        }, {
            id: 258,
            name: "十堰"
        }, {
            id: 259,
            name: "荆州"
        }, {
            id: 260,
            name: "宜昌"
        }, {
            id: 261,
            name: "荆门"
        }, {
            id: 262,
            name: "鄂州"
        }, {
            id: 263,
            name: "孝感"
        }, {
            id: 264,
            name: "黄冈"
        }, {
            id: 265,
            name: "咸宁"
        }, {
            id: 266,
            name: "随州"
        }, {
            id: 267,
            name: "恩施自治州"
        }, {
            id: 268,
            name: "仙桃"
        }, {
            id: 269,
            name: "天门"
        }, {
            id: 270,
            name: "潜江"
        }, {
            id: 271,
            name: "神农架林区"
        }, {
            id: 483,
            name: "黄岗"
        }]
    }, {
        id: 20,
        name: "湖南",
        children: [{
            id: 272,
            name: "长沙"
        }, {
            id: 273,
            name: "株洲"
        }, {
            id: 274,
            name: "湘潭"
        }, {
            id: 275,
            name: "衡阳"
        }, {
            id: 276,
            name: "邵阳"
        }, {
            id: 277,
            name: "岳阳"
        }, {
            id: 278,
            name: "常德"
        }, {
            id: 279,
            name: "张家界"
        }, {
            id: 280,
            name: "益阳"
        }, {
            id: 281,
            name: "郴州"
        }, {
            id: 282,
            name: "永州"
        }, {
            id: 283,
            name: "怀化"
        }, {
            id: 284,
            name: "娄底"
        }, {
            id: 285,
            name: "湘西自治州"
        }]
    }, {
        id: 21,
        name: "江西",
        children: [{
            id: 209,
            name: "南昌"
        }, {
            id: 210,
            name: "景德镇"
        }, {
            id: 211,
            name: "萍乡"
        }, {
            id: 212,
            name: "新余"
        }, {
            id: 213,
            name: "九江"
        }, {
            id: 214,
            name: "鹰潭"
        }, {
            id: 215,
            name: "赣州"
        }, {
            id: 216,
            name: "吉安"
        }, {
            id: 217,
            name: "宜春"
        }, {
            id: 218,
            name: "上饶"
        }, {
            id: 219,
            name: "抚州"
        }]
    }, {
        id: 22,
        name: "云南",
        children: [{
            id: 407,
            name: "昆明"
        }, {
            id: 408,
            name: "曲靖"
        }, {
            id: 409,
            name: "玉溪"
        }, {
            id: 410,
            name: "保山"
        }, {
            id: 411,
            name: "昭通"
        }, {
            id: 412,
            name: "普洱市"
        }, {
            id: 413,
            name: "临沧地区"
        }, {
            id: 414,
            name: "丽江"
        }, {
            id: 415,
            name: "文山自治州"
        }, {
            id: 416,
            name: "红河自治州"
        }, {
            id: 417,
            name: "西双版纳自治州"
        }, {
            id: 418,
            name: "楚雄自治州"
        }, {
            id: 419,
            name: "大理"
        }, {
            id: 420,
            name: "德宏自治州"
        }, {
            id: 421,
            name: "怒江自治州"
        }, {
            id: 422,
            name: "迪庆自治州"
        }]
    }, {
        id: 23,
        name: "安徽",
        children: [{
            id: 183,
            name: "合肥"
        }, {
            id: 184,
            name: "芜湖"
        }, {
            id: 185,
            name: "蚌埠"
        }, {
            id: 186,
            name: "淮南"
        }, {
            id: 187,
            name: "马鞍山"
        }, {
            id: 188,
            name: "淮北"
        }, {
            id: 189,
            name: "铜陵"
        }, {
            id: 190,
            name: "安庆"
        }, {
            id: 191,
            name: "黄山"
        }, {
            id: 192,
            name: "滁州"
        }, {
            id: 193,
            name: "阜阳"
        }, {
            id: 194,
            name: "宿州"
        }, {
            id: 195,
            name: "巢湖"
        }, {
            id: 196,
            name: "六安"
        }, {
            id: 197,
            name: "毫州"
        }, {
            id: 198,
            name: "宣城"
        }, {
            id: 199,
            name: "池州"
        }]
    }, {
        id: 24,
        name: "海南",
        children: [{
            id: 318,
            name: "海口"
        }, {
            id: 319,
            name: "三亚"
        }, {
            id: 320,
            name: "五指山"
        }, {
            id: 321,
            name: "琼海"
        }, {
            id: 322,
            name: "儋州"
        }, {
            id: 324,
            name: "文昌"
        }, {
            id: 325,
            name: "万宁"
        }, {
            id: 326,
            name: "东方"
        }, {
            id: 327,
            name: "澄迈县"
        }, {
            id: 328,
            name: "安定县"
        }, {
            id: 329,
            name: "屯昌县"
        }, {
            id: 330,
            name: "临高县"
        }, {
            id: 331,
            name: "白沙自治县"
        }, {
            id: 332,
            name: "昌江自治县"
        }, {
            id: 333,
            name: "乐东自治县"
        }, {
            id: 334,
            name: "陵水自治县"
        }, {
            id: 335,
            name: "保亭自治县"
        }, {
            id: 336,
            name: "琼中自治县"
        }]
    }, {
        id: 25,
        name: "贵州",
        children: [{
            id: 398,
            name: "贵阳"
        }, {
            id: 399,
            name: "六盘水"
        }, {
            id: 400,
            name: "遵义"
        }, {
            id: 401,
            name: "安顺"
        }, {
            id: 402,
            name: "铜仁地区"
        }, {
            id: 403,
            name: "毕节地区"
        }, {
            id: 404,
            name: "黔西南自治州"
        }, {
            id: 405,
            name: "黔东南自治州"
        }, {
            id: 406,
            name: "黔南自治州"
        }]
    }, {
        id: 26,
        name: "甘肃",
        children: [{
            id: 440,
            name: "兰州"
        }, {
            id: 441,
            name: "金昌"
        }, {
            id: 442,
            name: "白银"
        }, {
            id: 443,
            name: "天水"
        }, {
            id: 444,
            name: "嘉峪关"
        }, {
            id: 445,
            name: "定西地区"
        }, {
            id: 446,
            name: "平凉市"
        }, {
            id: 447,
            name: "庆阳市"
        }, {
            id: 448,
            name: "陇南地区"
        }, {
            id: 449,
            name: "武威市"
        }, {
            id: 450,
            name: "张掖市"
        }, {
            id: 451,
            name: "酒泉"
        }, {
            id: 452,
            name: "甘南自治州"
        }, {
            id: 453,
            name: "临夏自治州"
        }]
    }, {
        id: 27,
        name: "新疆",
        children: [{
            id: 465,
            name: "乌鲁木齐"
        }, {
            id: 466,
            name: "克拉玛依"
        }, {
            id: 467,
            name: "石河子"
        }, {
            id: 468,
            name: "吐鲁番"
        }, {
            id: 469,
            name: "哈密地区"
        }, {
            id: 470,
            name: "和田地区"
        }, {
            id: 471,
            name: "阿克苏地区"
        }, {
            id: 472,
            name: "喀什地区"
        }, {
            id: 473,
            name: "克孜勒苏柯尔克孜自治"
        }, {
            id: 474,
            name: "巴音郭楞自治州"
        }, {
            id: 475,
            name: "昌吉自治州"
        }, {
            id: 476,
            name: "博尔塔拉自治州"
        }, {
            id: 477,
            name: "伊犁自治州"
        }, {
            id: 481,
            name: "伊宁"
        }]
    }, {
        id: 28,
        name: "内蒙古",
        children: [{
            id: 92,
            name: "呼和浩特"
        }, {
            id: 93,
            name: "包头"
        }, {
            id: 94,
            name: "乌海"
        }, {
            id: 95,
            name: "赤峰"
        }, {
            id: 96,
            name: "通辽"
        }, {
            id: 97,
            name: "乌兰察布"
        }, {
            id: 98,
            name: "锡林郭勒盟"
        }, {
            id: 99,
            name: "乌兰察布盟"
        }, {
            id: 100,
            name: "鄂尔多斯市"
        }, {
            id: 101,
            name: "巴彦淖尔盟"
        }, {
            id: 102,
            name: "阿拉善"
        }, {
            id: 103,
            name: "兴安"
        }]
    }, {
        id: 29,
        name: "宁夏",
        children: [{
            id: 461,
            name: "银川"
        }, {
            id: 462,
            name: "石嘴山"
        }, {
            id: 463,
            name: "吴忠"
        }, {
            id: 464,
            name: "固原"
        }]
    }, {
        id: 30,
        name: "青海",
        children: [{
            id: 454,
            name: "西宁"
        }, {
            id: 455,
            name: "海东地区"
        }, {
            id: 456,
            name: "海北自治州"
        }, {
            id: 457,
            name: "海南自治州"
        }, {
            id: 458,
            name: "果洛自治州"
        }, {
            id: 459,
            name: "玉树自治州"
        }, {
            id: 460,
            name: "海西自治州"
        }, {
            id: 484,
            name: "黄南自治州"
        }]
    }, {
        id: 31,
        name: "西藏",
        children: [{
            id: 423,
            name: "拉萨"
        }, {
            id: 424,
            name: "那曲"
        }, {
            id: 425,
            name: "昌都地区"
        }, {
            id: 426,
            name: "山南地区"
        }, {
            id: 427,
            name: "日喀则"
        }, {
            id: 428,
            name: "阿里地区"
        }, {
            id: 429,
            name: "林芝地区"
        }]
    }]
};