export const k3k3k3erbutonghao = {
	balls:[
	   [-1,-1,-1,-1,-1,-1,-1,-1,-1,-1,-1,-1,-1,-1,-1]
	],
    num:[
	   [12,15,24,34,45,13,16,25,35,46,14,23,26,36,56]
    ],
    views:[
	   [12,15,24,34,45,13,16,25,35,46,14,23,26,36,56]
    ],
    titles: [
	   '二不同号'
    ],
    getNum:(balls)=>{
        let num = 0,arr = balls[0];
        for(let i=0,len=arr.length;i<len;i++){
            if(arr[i]>0){
                num++;
            }
        }
        return num;
    }
}
