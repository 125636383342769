export const sscquweiqujianwumaqujiansanxing =  {
	balls:[
        [-1,-1,-1,-1,-1],
        [-1,-1,-1,-1,-1],
        [-1,-1,-1,-1,-1,-1,-1,-1,-1,-1],
        [-1,-1,-1,-1,-1,-1,-1,-1,-1,-1],
        [-1,-1,-1,-1,-1,-1,-1,-1,-1,-1]
	],
    num:[
        [0,1,2,3,4],
        [0,1,2,3,4],
        [0,1,2,3,4,5,6,7,8,9],
        [0,1,2,3,4,5,6,7,8,9],
        [0,1,2,3,4,5,6,7,8,9]
    ],
    chooseType:['全','大','小','奇','偶','清'],
    views:[
        ['一区(0,1)','二区(2,3)','三区(4,5)','四区(6,7)','五区(8,9)'],
        ['一区(0,1)','二区(2,3)','三区(4,5)','四区(6,7)','五区(8,9)'],
        [0,1,2,3,4,5,6,7,8,9],
        [0,1,2,3,4,5,6,7,8,9],
        [0,1,2,3,4,5,6,7,8,9]
    ],
    titles: [
        '万',
        '千',
        '百',
        '十',
        '个'
    ],
    getLottery:(num,balls)=>{
        let str = '';
        for(let i = 0,len_i = balls.length;i < len_i;i++){
            str += i==0?'':'|';
		    for(let j = 0,len_j = balls[i].length;j < len_j;j++){
			    if(balls[i][j] > 0){
                   str += num[i][j];
				}
		    }
    	}
	    return str;
    },
    getNum:(balls)=>{
        let num = 1,nums = [];
        for(let i=0,len=balls.length;i<len;i++){
            nums[i] = 0;
            for(let j=0,len_j = balls[i].length;j<len_j;j++){
                if(balls[i][j]>-1){
                    nums[i]++;
                }
            }
        }
        for(let k = 0,len_k = nums.length;k<len_k;k++){
            num *= nums[k];
        }
        return num;
    },
    getView: (views,balls)=>{
        let str = '',area = ['一区','二区','三区','四区','五区']; 
        for(let i = 0,len_i = balls.length;i < len_i;i++){
            str += i==0?'':'|';
		    for(let j = 0,len_j = balls[i].length;j < len_j;j++){
			    if(balls[i][j] > 0){
                    str += (i==0||i==1)?area[j]:views[i][j];
				}
		    }
    	}
	    return str;
    }
}
