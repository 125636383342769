export  const sscerxingzhixuanqianerkuadu ={
    balls:[
        [-1,-1,-1,-1,-1,-1,-1,-1,-1,-1]
    ],
    num:[
        [0,1,2,3,4,5,6,7,8,9]
    ],
    views:[
        [0,1,2,3,4,5,6,7,8,9]
    ],
    titles: [
        '前二跨度'
    ],
    chooseType:['全','大','小','奇','偶','清'],
    getLottery:(num,balls)=>{
        let str = ''; 
        for(let i = 0,len_i = balls.length;i < len_i;i++){
            for(let j = 0,len_j = balls[i].length;j < len_j;j++){
                if(balls[i][j] > 0){
                    str += num[i][j];
                }
            }
        }
        return str;
    },
    getNum:(balls)=>{
        let num = 0,bets = [10,18,16,14,12,10,8,6,4,2];
        for(let i=0,len=balls[0].length;i<len;i++){
            if(balls[0][i]>-1){
                num += bets[i];
            }
        }
        return num;
    },
    getView: (views,balls)=>{
        let str = ''; 
        for(let i = 0,len_i = balls.length;i < len_i;i++){
            str = i==0?(str):(str+'|')
            for(let j = 0,len_j = balls[i].length;j < len_j;j++){
                if(balls[i][j] > 0){
                    str += views[i][j];
                }
            }
        }
        return str;
    }
}
