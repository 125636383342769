import React, {useEffect, useMemo, useState} from 'react';
import {widthDashBoard} from "../../components/dashboard/Dashboard";
import './a.scss'
import {panel, Tabs} from "../../components/tabs";
import {change, Pwd} from "../../components/pwd";
import {useQuery} from "../../hooks";
import {Portals} from "../../components/portals";
import {Table} from "../../components/table/table";
import {Copy} from "../../components/copy";
import {Loading} from "../../components/loading";
import {Paging} from "../../components/paging/Paging";
import {fmtErr, tip} from "../../utils";
import {Qrcode, Tip} from "../common/com";


const Detail = ({id, close}) => {
    const [q, ld, d] = useQuery('regLinkUser');
    const [b, bb] = useState(0);
    useEffect(() => {
        if (id) q({register_link_id: id});
        const t = setTimeout(() => bb(id), 350);
        return () => clearTimeout(t);
    }, [id]);
    const da = useMemo(() => {
        if (d && Array.isArray(d.data)) return d.data;
        return []
    }, [d])
    return <div className={'lk-dt-msk' + (id ? ' a' : '') + (b ? ' b' : '')} onClick={close}>
        <div className={'lk-pm'} onClick={e => e.stopPropagation()}>
            <div className={'lt'}><i/> 用户列表</div>
            <div className={'lks'}>
                <div className={'lss'}>
                    {da.map(({username, created_at}, i) => <div
                            key={i}
                            className={'po'}>
                            <span>{username}</span>
                            <span>{created_at}</span>
                        </div>
                    )}
                </div>
                <Loading act={ld}>正在加载</Loading>
            </div>
            <div onClick={close} className={'btn'}>确认</div>
        </div>
    </div>
};

let rmk;
const A = widthDashBoard((props, {
    globalStore: {user_level}
}) => {
    const [rnd, sRnd] = useState(0);
    const [dk, sdk] = useState(0);
    const defaultPwd = useMemo(() => {
        // return randomStr()
        return 'ob1234'
    }, [rnd])
    const [createA,] = useQuery('createAccount');
    const [createB,] = useQuery('createLink');
    const [a0, aa0] = useState('');
    const [ld, sLd] = useState('');
    const [b0, bb0] = useState('');
    const [t, tt] = useState(0);
    const [linkId, lk] = useState(0);
    const [c, cc] = useState(0);
    const [v0, vv0] = useState('');
    const [e0, ee0] = useState('');
    const [e1, ee1] = useState('');
    const [v1, vv1] = useState('');
    const [v2, vv2] = useState('');
    const [die, dDie] = useState(0);
    const [reg, regLinkLoading, result = {}] = useQuery('getRegLinks');
    const [delLk] = useQuery('delLink');
    const {data: rs = [], totalPage = 1} = result || {};
    const [dded, ddd] = useState({});
    const rows = rs.filter(({id}) => !dded[id]);
    const rk = <div className={'ipt'}>
        <input onChange={function (e) {
            rmk = e.target.value;
        }} type={'text'} placeholder={'备注'}/>
    </div>
    const cLink = () => {
        rmk = '';
        tip.alert({
            onClose(ok) {
                if (ok) submit(1);
                else {
                    rmk = ''
                }
            },
            no: true,
            yes: '提交',
            text: <div className={'s-inf'}>
                <label>请输入链接备注信息</label>
                {rk}
            </div>
        })
    }
    const sho = () => {
        if (!v1) return ee0('用户名不能为空');
        if (die || e0) return;
        let cpText = `
        开户类型: ${c ? '代理' : '玩家'},
        ${'用户名/密码: ' + v1 + ' / ' + (v2 || defaultPwd)}
        `;
        tip.alert({
            onClose(ok) {
                if (ok) submit()
            },
            no: true,
            yes: t ? '确认' : <Copy copyText={cpText} name={'确认并复制'}/>,
            text: <div className={'s-inf'}>
                <p>开户类型:<span>{c ? '代理' : '玩家'}</span>{panel(!t, <>用户名:<span>{v1}</span></>)}
                </p>
            </div>
        })
    };
    useEffect(() => {
        const t = setTimeout(() => bb0(a0 ? 'b' : ''), 301);
        return () => clearTimeout(t);
    }, [a0]);

    useEffect(() => {
        if (!regLinkLoading) ddd({})
    }, [regLinkLoading, result]);

    const load = () => {
        const o = {};
        reg(o)
    }

    useEffect(load, [])
    const del = id => {
        if (!id) return;
        ddd({...dded, [id]: 0})
        delLk({id}, () => {
                ddd({...dded, [id]: 1})
            },
            () => {
                delete dded[id];
                ddd({...dded});
            }
        );
    };
    const clean = () => {
        sRnd(Math.random());
        sdk(Math.random());
        rmk = '';
        vv2('');
    }
    const submit = (t = 0) => {
        const o = {
            is_agent: t ? 0 : c,
            // ...cR,
        };
        if (!t) {
            o.password = v2 || defaultPwd;
            o.username = v1.replace(/^\s+|\s+$/g, '').toLowerCase();
        } else {
            o.remark = rmk
        }
        sLd('正在提交...');
        [createA, createB][t](o, (d) => {
            aa0('');
            sLd('');
            tip.alert(<Tip.Ok>开户成功!</Tip.Ok>)
            clean();
            if (t) {
                load()
            }
        }, er => {
            sLd('');
            tip.alert(<Tip.Err>{fmtErr(er)}</Tip.Err>);
        });
        if (t) {
            //todo
        }
    };
    const ipt = (cls = '', p, v, vv, i, ru, rex, er, eer) => {
        const f = () => {
            eer(0);
            vv0(ru);
        };
        const b = () => {
            vv0('');
            if (!i && !v) return eer(p);
            if (v && !rex.test(v)) return eer(ru);
            eer(0)
        };
        return <form
            onSubmit={e=>e.preventDefault()}
            autoComplete={'off'}
            className={'ipt-a' + (er ? ' err' : '')}>
            {er ? <p><b>X</b>{er}</p> : ''}
            <label> <i className={cls}/></label>
            {i ?
                <>
                    <Pwd onChange={vv} value={v} placeholder={p} blur={b} focus={f}/>
                    {/*{v ? null : <i className={'ld'} onClick={() => sRnd(Math.random())}/>}*/}
                </> :
                <input autoComplete={"off"} onChange={change(vv)} value={v} type={'text'} placeholder={p} onFocus={f}
                       onBlur={b}/>}
        </form>;
    };

    const uType = t !== 0 || user_level > 1 ? null : <div className={'c2'}>
        <Tabs change={cc} act={c}>
            {o => <div className={'c21'}>
                <div {...o(0)}>{'玩家'}</div>
                <div {...o(1)} >{'代理'}</div>
            </div>}
        </Tabs>
    </div>
    const links = <div className={'q'}>
        <label className={'lti'}>推广链接</label>
        <div className={'btn'} onClick={cLink}>新增链接</div>
        <div className={'link-his'}>
            <Table
                className={'x-table'}
                row={[
                    {
                        name: '编号', cell(_, i) {
                            return i
                        }
                    },
                    {key: 'remark', name: '备注'},
                    {
                        name: '域名地址',
                        cell: ({url}) => <div className={'cpl'}>
                            <a href={url} target={'_blank'}><s/><span>{url}</span></a>
                            <Copy className={'cp'} copyText={url}/>
                        </div>
                    },
                    {
                        cell: ({created_count, id}) => {
                            return <div className={'opt'}>
                                <div onClick={() => {
                                    lk(id)
                                }}>{created_count || 0}
                                </div>
                            </div>
                        }, name: '注册会员数'
                    },
                    {
                        cell: ({charge_count, id}) => {
                            return <div className={'opt'}>
                                <div onClick={() => {
                                    // lk(id)
                                }}>{charge_count || 0}
                                </div>
                            </div>
                        },
                        name: '充值会员数'
                    },
                    {
                        name: '操作', cell: ({id, url}) => {
                            const x = dded[id] === 0;
                            return <div className={'opt'}>
                                <div onClick={() => {
                                    tip.alert({
                                        showClose:true,
                                        text: (c) => <div className={'s-inf'}>
                                            <Qrcode download={c()} text={url}/>
                                        </div>,
                                        yes: false,
                                        no: false
                                    })
                                }}>二维码
                                </div>
                                <div onClick={() => !x && del(id)}>{x ? '删除中' : '删除'}</div>
                            </div>
                        }
                    },
                ]} data={rows}/>
            <Loading act={regLinkLoading}>
                正在加载...
            </Loading>
            <div className={'fl'}/>
            <Portals>
                <Detail id={linkId} close={() => lk(0)}/>
            </Portals>
        </div>
        <div className={'c-tip'}>
            <p>最多支持添加20条推广链接</p>
            <p>添加普通域名：输入域名备注，系统将自动生成推广域名和二维码。</p>
            <p>添加专属：</p>
            <p>(1)需要条件：上月及本月公司总输赢大于5万，并且有效会员达到5位；</p>
            <p>(2)达到条件的代理方可联系<b>代理服务部</b>进行申请；</p>
            <p>(3)审核通过后，技术部将为您开通专属域名</p>
        </div>
    </div>;
    const share = <div className={'shr'}>
        <div className={'c0'}>
            <label className={'lti'}>人工开户</label>
            <div className={'c1'}>
                {uType}
                {panel(t === 0, <div className={'c3'}>
                    {ipt('usr', '请输入用户名', v1, vv1, 0,
                        '用户名须6-16位字符，可使用字母或数字', /^[0-9a-zA-Z]{6,16}$/gi, e0, ee0)}
                    {ipt('pwd', '默认密码 ' + defaultPwd, v2, vv2, 1,
                        '密码须6-16位字母数字组合且连续三位不能相同', /^(?=.*\d+)(?=.*[a-zA-Z]+)(?!.*?([a-zA-Z0-9]{1})\1\1).{6,16}$/g, e1, ee1)}
                    <div className={'btn'} onClick={ld ? null : sho}><span>{ld ? '正在提交...' : '立即开户'}</span></div>
                </div>)}
            </div>
            <Loading act={ld}>{ld}</Loading>
        </div>
        {user_level?links:''}
    </div>;
    return <div className={'accPage x-sheet'}>
        <div className={'c x-tba'}>
            {share}
        </div>
    </div>
});
export const AccountPage = props => {
    return <A {...props} cls={'xt'}/>
};
