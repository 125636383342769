export const CATE_NAMES = [
    ['live', '真人'],
    ['rng', '电子'],
    ['fish', '捕鱼'],
    ['pvp', '棋牌'],
    ['sport', '体育']
];
export const default_jump= 'https://www.whlz.cc';
export const LAUNCH_CONFIG = {
    lottery:{

    },
    pvp: {
        15: ['KY0001', 'd5', '开元棋牌', 'html5,flash',1,'']
    },
    fish: {
        4: ['G00009', 'd0', '', 'html5,html5-desktop',1,''],
        2: ['HMPL', 'd3', 'AG捕鱼', 'html5,flash',1,''],
        25: ['7003', 'd4', 'JDB捕鱼', 'html5,html5-desktop',1,''],
    },
    sport: {
        14: ['IBC067', 'bs', '沙巴体育', 'flash', 1],
        // 16: ['AV0019', 'bti', '泛亚电竞', 'html5,flash,html5-desktop', 1],
        18: ['ALB001', 'alb', '188体育', 'html5,flash', 1],
        19: ['BTI001', 'bt', 'OB体育', 'html5,flash,html5-desktop', 1],
        24: ['esportsbull', 'im', 'IM电竞', 'html5,flash,html5-desktop', 1],
        28: ['IMSB51', 'im', 'IM体育', 'html5,flash,html5-desktop', 1],
        27: ['lh', 'lh', '雷火电竞', 'html5,flash,html5-desktop', 1],
        26: ['ob', 'ob', 'OB电竞', 'html5,flash,html5-desktop', 1],
        30: ['ob', 'ob', 'BT体育', 'html5,flash,html5-desktop', 1],
    },
    elect:{

    },
    live: {
        3: ['M00655', 'a3', '', 'html5-desktop'],
        2: ['A00070', 'a0', '', 'flash,html5-desktop'],
        5: ['BB1001', 'a1', '', 'html5,html5-desktop'],
        7: ['EA0001', 'a2', '', 'flash'],
        17: ['SEX001', 'a4', 'Sexy gaming', 'html5,flash,html5-desktop'],
        20: ['EG0001', 'a6', '', 'html5,flash,html5-desktop'],
        22: ['YB0001', 'a7', '', 'html5,flash,html5-desktop']
    }
};

export const NAV_ACTIVITIES = {
    sport: '5',
    pvp: '',
    live: '3',
    elect: '',
    lottery: '2',
    rng: '6',
    fish: '',
}

export const GAME_SUBTYPE = [
    [undefined, '累计奖池'],
    ['SM', '老虎机'],
    ['VP', '视频扑克'],
    ['TG', '桌面游戏'],
    ['SC', '刮刮乐'],
    ['AC', '游戏竞技'],
    ['ROTATE', '再次旋转'],
];

export const COMMISSION_TYPES = {
    lottery: 1,
    sport: 2,  //体育
    live: 3,  //真人
    rng: 4,  //老虎机
    pvp: 5,  //棋牌
    fish: 6  //捕鱼
};

export const ROTATE_RNG = {
    3: [
        /比基尼派对/, /舞龙/,
        /刺青酒店/, /宝石之轮/,
        /经典老虎机/, /钻石浮华/,
        /东方珍兽/, /酷热经典/,
        /OZ之书/i, /好运经纪人/,
        /再抢银行在旋转/, /之书锁定并旋转/, /幸运富豪/],
    11: [
        /跳更高/, /飞起来/, /金鸡报囍2/,
        /直式跳更高/, /金鸡报囍([3三])/,
        /直式金鸡报囍([2二])/, /转珠猪/],
    6: [/宝石财富/, /美杜莎的诅咒/]
}


export const WALLETS = {
    22: 'OB真人',
    23: 'OB棋牌',
    21: '盘口彩票',
    19: 'BT体育',
}


export const WType = {
    get balance() {
        return {"type": "balance"}
    },
    get issue() {
        return {"type": "issue"}
    },
    subLottery(id) {
        return {"type": "joinGroup", lottery_id: id}
    },
    unSubLottery(id) {
        return {"type": "leaveGroup", lottery_id: id}
    },
    getIssue(id) {
        return {"type": "issue", lottery_id: id}
    }
}
