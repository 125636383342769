import ClipboardJS from 'clipboard';
import React, {useState, useRef, useEffect} from 'react';

export const Copy = ({className, children = [], copyText, name = '复 制', done = '已复制'}) => {
    const [t, setT] = useState(name);
    const btn = useRef();
    let timer = -1,umt=1;
    useEffect(() => {
        const {current} = btn;
        if (current) {
            new ClipboardJS(current, 'div').on('success', function () {
                setT(done);
                clearTimeout(timer);
                timer = setTimeout(() => umt&&setT(name), 2e3);
            });
        }
    }, [copyText, btn.current]);
    useEffect(()=>() =>umt=0,[]);
    return <div className={className} data-clipboard-text={copyText} ref={btn}>
        {children.length ? children : t}
    </div>
};