import React, {useState, useEffect} from 'react';
import {useQuery} from "../../hooks";
import {Portals} from "../portals";
import './s.scss';
import {widthStore} from "../../store";

const Box = ({items, onClose}) => {
    const [nTd, st] = useState(true);
    const id = (items[0] || {}).id || 0;
    const [act, ss] = useState(id);
    const [idx, dd] = useState(1);
    const [h, hh] = useState(-1);
    const [im, ii] = useState([]);
    const l = items.length;
    useEffect(() => {
        ss(id);
    }, [id]);
    useEffect(() => {
        ii(items.concat(items).slice(idx, idx + 3));
        let t  = setTimeout(() => {
            hh(0)
        }, 8e3);
        return () => clearTimeout(t);
    }, [idx, id, items]);
    useEffect(() => {
        let t = -1;
        if (im.length === 3 && !h) {
            t = setTimeout(() => {
                dd((idx + l + 1) % l);
                hh(-1);
            }, 1e3);
        }
        return () => clearTimeout(t);
    }, [h, im.length, l]);
    const item = items.find(({id: i}) => i === act) || {};
    const {title = '', content = '', created_at = ''} = item;
    return <div className={'pop-n-box'}>
        <div className={'pop-close'} onClick={() => {
            const n = Date.now() + '';
            localStorage.setItem('ls_pop', JSON.stringify([id, nTd ? n : '']));
            if (onClose) onClose();
        }}/>
        <div className={'pop-hd'}>
            <span>{title}</span>
        </div>
        <div className={'pop-ctx'}>
            <div dangerouslySetInnerHTML={{__html: content}}/>
        </div>
        <div className={'ct'}>{created_at}</div>
        <div className={'pop-ft'}>
            <div className={'more'}>{im.map(({title, id}, i) =>
                <div
                    key={i}
                    className={i === h ? 'h' : ''}
                    onClick={() => ss(id)}><p>{title}</p></div>)}</div>
            <div className={'no-today'} onClick={() => st(!nTd)}>
                <i className={nTd ? 'act' : ''}/>
                不再显示
            </div>
        </div>
    </div>
};

export const PopNotice = widthStore(({globalStore}) => {
    const {token} = globalStore
    const [show, ss] = useState(false);
    const [query, loading, res] = useQuery('article');
    useEffect(() => {
        if (token) query()
    }, [token]);
    useEffect(() => {
        if (!loading && res && res.length) {
            const aId = res[0].id;
            let lastShow = [];
            try {
                const a = localStorage.getItem('ls_pop');
                if (a) {
                    lastShow = JSON.parse(a);
                }
            } catch (e) {
            }
            const [id, tm] = lastShow;
            if (id !== aId || !tm) {
                ss(true)
            }
        }
    }, [res, loading]);
    return <Portals root={()=>document.body}>
        <div className={'pop-notice-mask' + (show ? ' act' : '')}>
            <Box items={res||[]} onClose={() => {
                ss(false)
            }}/>
        </div>
    </Portals>
});
