import React, {useEffect, useMemo, useState} from 'react';
import './c.scss';
import {Loading} from "../../components/loading";
import {useQuery} from "../../hooks";
import {panel, Tabs} from "../../components/tabs";
import {change} from "../../components/pwd";
import {numIpt, processPay, quickAmount, tip, trim} from "../../utils";
import {Copy} from "../../components/copy";
import {popWallet} from "./popWallet";
import {NoDate} from "../common/com";
import {Select} from "../../components/select/select";
import {widthStore} from "../../store";

const PayPage = ({data, timeUp, req}) => {
    const n = useMemo(() => ({}), [])
    const [t, tt] = useState(1);
    const {aBankInfo = {}, aApplyInfo = {}, redirectURL} = data || {};
    const {name: fNm, identifier} = aApplyInfo;
    const {
        identifier: tId, name: tNm, note,
        bank_address, amount, account, account_name
    } = aBankInfo;
    const isAli = /ALIPAYBANK/i.test(identifier);
    const isWx = /WXBANK/i.test(identifier);
    const netBank = !/alipay|wx/i.test(identifier) && /bank/.test(identifier) && amount;
    useEffect(() => {
        if (netBank) {
            n.end = Date.now() + 1800000;
            tt(n.end - Date.now())
        }
    }, [data, n])
    useEffect(() => {
        if (netBank) {
            const v = setInterval(() => {
                if (t <= 0) {
                    clearInterval(v);
                    timeUp && timeUp()
                    t(1);
                }
                tt(n.end - Date.now())
            }, 1e3)
            return () => clearInterval(v)
        }
    }, [data])
    if (!data) return null;
    const amt = amount || req;
    // const {limit, payRes: {aBankInfo = {}, aApplyInfo = {}}, payTime, bankUrls} = data;
    return <div className={'pay-result'}>
        <div className={'lf'}>
            <div className={'amt'}>
                <span>充值金额</span>
                <div>{amt}</div>
            </div>
            {panel(!amount, <>
                <div className={'tip'}>
                    <i/>
                    <p>请在第三方页面完成充值。{redirectURL ? <> 如未弹出页面，请<a
                        onClick={() => processPay(data)}>点击此处</a>前往第三方支付。</> : null}</p>
                </div>
            </>)}
            {panel(amount, <>
                    <div className={'tip'}>
                        <i/>
                        <p>充值时请照“系统生成代带小数点的金额”转账，否则无法自动到账，转账前请核对“开户银行”.</p>
                    </div>
                    <div className={'pay-inf'}>
                        <div className={'f'}>
                            <div className={'r'}>
                                <label>收款人账户名：</label>
                                <span>{account_name}</span>
                                <Copy className={'cp'} copyText={account_name}/>
                            </div>
                            <div className={'r'}>
                                <label>卡号：</label>
                                <span>{account}</span>
                                <Copy className={'cp'} copyText={account}/>
                            </div>
                            <div className={'r'}>
                                <label>开户银行：</label>
                                <span>{bank_address}</span>
                                <Copy className={'cp'} copyText={bank_address}/>
                            </div>
                        </div>
                        {panel(netBank, <div className={'tm'}>
                            {Math.floor(t / 60000)}:{('' + (100 + Math.floor(t % 60000 / 1000))).substr(1)}
                        </div>)}
                    </div>
                </>
            )}
            <div className={'btns'}>
                <div className={'btn gr'} onClick={() => {
                    timeUp && timeUp();
                    popWallet.hide()
                }}>已完成
                </div>
                <div className={'btn'} onClick={timeUp}>返回继续存款</div>
            </div>
        </div>
        {panel(isAli, <div className={'ali'}>
            <span>充值说明：</span>
            <div className={'s0'}/>
        </div>)}
        {panel(isWx, <div className={'we'}>
            <span>充值说明：</span>
            <div className={'s0'}/>
        </div>)}
    </div>
}

export const Charge = widthStore(({globalStore: {is_agent}}) => {
    const [op, opp] = useState(0);
    const [un, uun] = useState('');
    const [getChannel, ld, res] = useQuery('getChannel');
    const [charge, cLd] = useQuery('charge');
    const [getPro, pLd, pLs] = useQuery('activities');
    const [v0, vv0] = useState('');
    const [v1, vv1] = useState('');
    const [t0, tt0] = useState(0);
    const [t1, tt1] = useState(0);
    const [da, dd] = useState(null);
    const tps = useMemo(() => {
        if (res) return Object.values(res)
        return [];
    }, [res]);
    useEffect(() => {
        tt1(0);
        dd(null);
        getPro({type: 4})
    }, [t0])
    const ws = tps.length ? <div className={'r '}>
        <label>存款方式</label>
        <Tabs change={tt0}>{o => <div className={'r way'}>{tps.map(({identifier, name}, i) =>
            <div {...o(i)} key={i}>
                <div className={'c'}>
                    <i className={identifier}/>
                    <b><span>✓</span></b>
                </div>
                <span>{name}</span>
            </div>)}</div>}</Tabs>
    </div> : null;

    const rate = useMemo(() => {
        const d = tps && tps[t0]
        if (d && d.identifier === 'USDT') {
            const cs = (tps[t0] || {}).children;
            const c = cs && cs[t1] || {};
            const {exchange_rate} = c;
            return +exchange_rate
        }
        return 0
    }, [tps, t0, t1]);
    const fRate = useMemo(() => {
        const cs = (tps[t0] || {}).children;
        const c = cs && cs[t1] || {};
        const {fee_rate} = c;
        return +fee_rate
    }, [tps, t0, t1])
    const usdt = useMemo(() => {
        if (!rate) return '';
        const v = v0 / rate;
        if (v) {
            return '预计支付:' + (v).toFixed(2, 1) + 'USDT'
        }
        return '';
    }, [rate, v0])
    const chs = useMemo(() => {
        const cs = (tps[t0] || {}).children;
        if (cs) {
            if (!da && cs && cs.length) {
                return <div className={'r ch'}>
                    <label>选择渠道</label>
                    {cs.map(({id, name}, i) => <span
                        key={i}
                        onClick={() => tt1(i)}><i className={t1 === i ? 'act' : ''}/>
                        {name}</span>)}
                </div>
            }
        }
        return null;
    }, [tps, t0, t1])
    const bnk = useMemo(() => ((tps[t0] || {}).children || [])[t1], [tps, t0, t1])
    useEffect(() => {
        const {min_load} = bnk || {}
        if (min_load) vv0(min_load);
    }, [bnk])
    const {
        id,
        fixed_amount,
        max_load,
        min_load,
        hundred,
        deposit_mode,
        support_bank_ids
    } = bnk || {}
    const quick = fixed_amount ? fixed_amount.split(',') : quickAmount(min_load, max_load)
    useEffect(() => {
        getChannel();
    }, []);
    const cur = v1 && (pLs || []).find(a => a.id === v1);
    const tips = useMemo(() => {
        if (cur) {
            const {min_amount, max_amount, game_type} = cur;
            const p = ['', '彩票', '体育', '真人', '电子', '棋牌', '捕鱼'][game_type]
            const ok = !min_amount || (v0 >= +min_amount);
            return [
                <p className={'tip ' + (ok ? 'gr' : '')}>
                    {min_amount ? '最低存款' + min_amount + '元' : ''}
                    {max_amount ? '，最高奖金' + max_amount + '元' : ''}
                    {p ? '，仅适用于' + p + '类型' : ''}
                </p>, ok
            ]
        }
        return [];
    }, [cur, v0])
    let iptErr = 0;
    if (bnk && v0) {
        if (!parseFloat(v0)) iptErr = 1;
        if (max_load !== null) {
            if (+v0 > +max_load) iptErr = 1
        }
        if (min_load !== null) {
            if (+v0 < +min_load) iptErr = 1
        }
        if (hundred && v0 % 100) {
            iptErr = 1
        }
    }
    const isOk = v0 && !iptErr;
    const [tp, ok] = tips
    return <div className={'x-sheet charge-page'}>
        {tps.length ? <>
            {ws}
            {chs}
            <div className={'pay-ctx'}>
                {fRate ? <p className={'fr'}>温馨提示：此渠道用户需承担 {fRate.toFixed(2)}% 的充值手续费</p> : null}
                {rate ? <div className={'r pm'}>
                    <label>协议</label>
                    <div className={'pt'}>TRC20</div>
                </div> : null}
                <div className={'r am'}>
                    <label>存款金额</label>
                    <div className={'a' + (v0 ? iptErr ? ' er' : ' ok' : '')}>
                        {usdt ? <span className={'ud'}>{usdt}</span> : null}
                        <input
                            placeholder={(fixed_amount ? '' : '请输入') + '存款金额'}
                            readOnly={!!fixed_amount}
                            className={iptErr ? 'er' : ''}
                            onFocus={({target}) => target.select()}
                            value={v0}
                            onChange={change(vv0, numIpt)}/>
                        <div>
                            <s/>
                            <span className={v0 ? iptErr ? 'er' : 'ok' : ''}>
                            单笔充值额度为{min_load}-{max_load}元
                                {hundred ? ',且金额必须为整百数字' : ''}
                        </span>
                        </div>
                        {is_agent ? <div className={'otherP'} onClick={() => opp(1 - op)}>
                            <i className={op ? 'act' : ''}/>代充值
                        </div> : null}
                    </div>
                </div>
                {quick.length ? <div className={'q'}>
                    {
                        quick.map((a, i) => {
                            const act = parseFloat(v0) === parseFloat(a);
                            return <span key={i} className={act ? 'act' : ''} onClick={() => vv0(a)}>{a}</span>
                        })
                    }
                </div> : null}
                {op ? <div className={'r'}>
                    <label>代充值</label>
                    <input
                        placeholder={'请输入代充值用户名'}
                        onFocus={({target}) => target.select()}
                        value={un}
                        onChange={change(uun, trim)}/>
                </div> : null}
                {rate ? <div className={'r'}>
                    <label>参考汇率</label>
                    <div className={'ra'}>
                        1USDT = {(1 * rate).toFixed(2, 1)}CNY
                    </div>
                </div> : null}
                <div className={'r'}>
                    <label>选择优惠</label>
                    <Select
                        items={(pLs || []).map(({id, name}) => [id, name])}
                        placeholder={pLs && pLs.length ? '请选择优惠' : '暂无优惠'}
                        onChange={vv1}
                        value={v1}
                        loading={pLd ? '正在加载优惠' : ''}/>
                    {tp}
                </div>
                <div className={'sub btn' + (isOk ? '' : ' dis')}
                     onClick={() => {
                         if (cLd || !isOk) return;
                         const next = () => {
                             const o = {
                                 identifier: (tps[t0] || {}).identifier,
                                 payment_id: id,
                                 deposit_mode: deposit_mode,
                                 bank_id: support_bank_ids,
                                 amount: parseFloat(v0).toFixed(2, 1)
                             };
                             if (is_agent && op && un) {
                                 o.username = un
                             }
                             if (v1 && ok) o.activity_id = v1;
                             charge(o, d => {
                                 dd(d)
                             });
                         }
                         if (v1 && !ok) {
                             tip.alert(
                                 {
                                     title: '温馨提醒',
                                     text: `充值金额不符优惠条件，继续充值将会忽略该优惠`,
                                     no: '返回修改',
                                     yes: '继续充值',
                                     onClose(n) {
                                         if (n) {
                                             next()
                                         }
                                     }
                                 }
                             );
                         } else next()
                     }}
                >{cLd ? '提交中...' : '立即存款'}</div>
                <PayPage data={da} timeUp={() => {
                    vv0('');
                    dd(null)
                }} req={parseFloat(v0).toFixed(2, 1)}/>
            </div>
        </> : <NoDate>暂无充值渠道</NoDate>}
        <Loading act={ld}>正在加载存款渠道</Loading>
    </div>
})
