import React, {useMemo, useState} from 'react';
import {PopUpWin} from "../../components/popup/popup";
import {widthStore} from "../../store";
import './a.scss';
import {useQuery} from "../../hooks";

export const popAvatar = {};
const A = ({i, act, onClick}) => {
    return <div onClick={() => onClick(i)} className={'ava-' + i + (act ? ' act' : '')}/>
}
export const PopAva = widthStore(({
                                      globalStore: {avatar}
                                  }) => {
    const [set, ld] = useQuery('setUserInfo')
    const ctx = useMemo(()=>({}),[])
    const [v, vv] = useState(avatar);
    const o = i => <A key={i} onClick={vv} i={i} act={v === i}/>
    const avs = []
    for (let i = 0; i < 30; i++) {
        avs.push(o(i))
    }
    return <PopUpWin
        maskCloseAble
        cls={'ava'}
        onShow={ss => {
            ctx.ss=ss
            vv(avatar)
        }}
        read={o => Object.assign(popAvatar, o)}>
        <div className={'ava-ls'}>
            <div className={'t'}>更换头像</div>
            <div className={'ls'}>
                {avs}
            </div>
            <div className={'btn'} onClick={ld ? null : () => set({avatar: v},()=>{
                ctx.ss&&ctx.ss(0)
            })}>{ld ? '提交中' : '确定'}</div>
        </div>
    </PopUpWin>
})
