import React, {useState, useEffect} from 'react';
import {Portals} from "./portals";

/***
 * 加载组件
 * @param {boolean} [fixed] 是否再最外显示 true 填满body窗口 default: false- 填满当前定位元素[offsetParent]
 * @param {boolean} act  是否显示
 * @param children
 * @return {*}
 * @constructor
 * @example <Loading>正在加载...</Loading>
 */
export const Loading = ({fixed, children, act}) => {
    const [s, ss] = useState(false);
    useEffect(() => {
        let timer = -1;
        clearInterval(timer);
        timer = setTimeout(() => ss(act), 100)
        return () => clearTimeout(timer);
    }, [act]);
    const load = <div className={"x-loading " + (fixed ? "fixed" : "") + (act ? ' a' : '') + (s ? ' b' : '')}>
        <span className={'in'}><div><i><i/></i></div><div>{children}</div></span>
    </div>;
    if (fixed) return <Portals>
        {load}
    </Portals>;
    else return load
};