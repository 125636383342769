import React, {useState, useEffect} from 'react';
import './confirm.scss';
import {Portals} from "../portals";
import {tip, useMove} from "../../utils";
import {fromEvent, merge} from "rxjs";
import {map, switchMap, takeUntil, withLatestFrom} from "rxjs/operators";
import {useEventCallback} from "rxjs-hooks";

/**
 * @link tip.alert
 * @return {*}
 * @constructor
 */
export const Confirm = () => {
    const [cf, setCf] = useState({});
    const [i, setI] = useState(0);
    useEffect(() => {
        tip.alert = (cfg) => {
            if (cfg && !cfg.text) {
                cfg = {text: cfg}
            }
            setCf(cfg)
        };
    }, []);
    const {
        showClose,
        mask,
        title,
        text,
        yes,
        no,
        clsY,
        clsN,
        check,
        align,
        maskCloseAble = false,
        onClose
    } = cf;
    const [onMouseDown, style, reset] = useMove(text)
    const yy = yes ? yes : "确定";
    const n = no === true ? "取消" : no;
    const close = n => e => {
        if(e)e.stopPropagation();
        if (onClose) onClose(n, i);
        setCf({...cf, text: ""});
        reset()
    };

    return <Portals>
        <div className={"confirm-box " + (text ? "act" : "")}>
            {mask ? <div className={"mask"} onClick={maskCloseAble ? close() : null}/> : ""}
            <div className={"c"} style={style} onMouseDown={onMouseDown}>
                {showClose?<i className={'clo'} onClick={close()}/>:null}
                <i className={'c-logo'}/>
                <div className={"ctx"} style={{textAlign: align}} onMouseDown={e => {
                    e.stopPropagation();
                }}>
                    {title ? <div className={"t"}>{title}</div> : ""}
                    <div className={'c-ctx'}>
                        {typeof text === 'function' ? text(close) : text}
                        {check ? <div className={'chk'}><label><input type={"checkbox"} checked={i}
                                                                      onChange={e => setI(e.target.checked)}/>{check}
                        </label></div> : null}
                    </div>
                    <div className={"btn"}>
                        {yes !== false ? <div onClick={close(true)} className={clsY || "y"}>{yy}</div> : null}
                        {no ? <div onClick={close()} className={clsN || "n"}>{n}</div> : ""}
                    </div>
                </div>
            </div>
        </div>
    </Portals>
};
