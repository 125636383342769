import './m.scss'
import React, {useState, useEffect, useMemo} from 'react'
import {widthDashBoard} from "../../components/dashboard/Dashboard";
import {DashTitle, Tip} from "../common/com";
import {Select} from "../../components/select/select";
import {useQuery} from "../../hooks";
import {Loading} from "../../components/loading";
import {panel, Tabs} from "../../components/tabs";
import {change} from "../../components/pwd";
import {Table} from "../../components/table/table";
import {Paging} from "../../components/paging/Paging";
import {tip, trim} from "../../utils";
import {stores} from '../../store';

const Detail = ({id}) => {
    const [q, l, d] = useQuery('msgView');
    const t = (d || {}).msg_content || '';
    useEffect(() => {
        if (id) q({id})
    }, [id])
    return <div className={'da-c'}>
        <pre>{t}</pre>
        <Loading act={l}>正在加载内容...</Loading>
    </div>
}

const A = widthDashBoard(() => {
    const {globalStore: {is_agent}} = stores;
    const [[tm, rd, un], sum] = useState([0, 0, 0]);
    const [msgList, mm] = useState([]);
    const [sel, sSel] = useState({});
    const [r, rrd] = useState(0);
    const [a, aa] = useState(1 - is_agent);
    const [vm, vvm] = useState(null);
    const [a0, aa0] = useState(0);
    const [t, tt] = useState(3);
    const [totalPage, ta] = useState(1);
    const [cPage, ccp] = useState(1);
    const [down, dd] = useState([]);
    const [f, ff] = useState('');
    const [getContact, loading, da] = useQuery('contacts');
    const [getMsg, mLd, msg] = useQuery('getMsg');
    const [send, sending] = useQuery('sendMsg');
    const [del, dLd] = useQuery('delMsg');
    const ft = useMemo(() => {
        const v = f.replace(/^\s+|\s+$/, '').toLowerCase();
        return (down || []).filter(([_, n]) => n.indexOf(v) !== -1);
    }, [down, f]);
    const sTotal = Object.keys(sel).reduce((a, b) => a + sel[b], 0)
    const selMsg = (id, v) => {
        sSel({...sel, [id]: v});
    }
    const col = [
        {
            name: '', cell({msg_id}) {
                const x = +!!sel[msg_id];
                return <div className={'sel' + (x ? ' act' : '')} onClick={(e) => {
                    e.stopPropagation();
                    selMsg(msg_id, 1 - x)
                }}/>
            }
        },
        {name: '主题', key: 'msg_title'},
        {name: '发送时间', key: 'created_at'}
    ]
    if (a === 1) col.unshift({name: '发件人', key: 'sender'})
    const [subject, ss] = useState('');
    const [ctx, cc] = useState('');

    const selected = t ? down.filter(([, , , sel]) => sel) : [];

    const ck = (sel, x) => () => {
        const o = down.slice();
        o.find(([id]) => id === x)[3] = 1 - sel;
        dd(o)
    };


    const R = ({id, name, online, sel}) => {
        return <div className={'ur' + (online ? '' : ' off')} onClick={ck(sel, id)}>
            <i/>
            <label>{name}</label>
            <b className={sel ? 'act' : ''}/>
        </div>
    };
    useEffect(() => {
        if (da && !loading) {
            try {
                dd(da.map(({id, username, is_online}) => {
                    return [id, username, is_online, 0]
                }));
            } catch (e) {
            }
        }
    }, [da, loading]);
    useEffect(() => {
        const p = {
            page: 1,
            page_size: 10000,
        };
        getContact(p);
    }, []);

    useEffect(() => {
        if (a) {
            sSel({});
            getMsg({is_receiver: 2 - a})
        }
    }, [a]);

    useEffect(() => {
        mm([]);
        if (!mLd && msg) {
            rrd(0);
            const t = msg.data || [];
            let [e, b, c] = [0, 0, 0];
            t.forEach(({is_readed}) => {
                e++;
                if (is_readed) b++
                else c++;
            })
            sum([e, b, c]);
            mm(t);
        }
    }, [mLd, msg])

    const submit = () => {
        const [a, b] = [trim(subject), trim(ctx)];
        const receiver_id = selected.map(([a]) => a).join(';');
        if (sending || !a || !b) return;
        const params = {
            title: subject,
            content: ctx
        }
        if (t === 3) {
            if (!receiver_id) return;
            params.receiver_id = receiver_id;
        } else  if(t)params.is_agent = 2 - t;
        send(params);
    }

    const l = down.length;
    const detail = useMemo(() => {
        if (vm) {
            const {msg_title, receiver, sender, id} = vm;
            const v = a - 1;
            return <div className={'detail'} onClick={e => e.stopPropagation()}>
                <div className={'da-t'}><label>主题</label>{msg_title}</div>
                <div className={'da-r'}><label>{['发件人', '收件人'][v]}</label>{[sender, receiver][v]}</div>
                <Detail id={id}/>
            </div>
        }
    }, [vm])
    const sF = (v) => {
        if (!v) return sSel({})
        const o = {};
        msgList.forEach(({msg_id}) => o[msg_id] = 1)
        sSel(o);
    }
    // eslint-disable-next-line react-hooks/rules-of-hooks
    const s = Object.values(sel);
    const isFull = msgList.length === s.length && s.indexOf(0) === -1
    const delMsg = () => {
        const d = Object.keys(sel).filter(k => sel[k]).join(',')
        if (d) del({id: d}, () => {
            tip.alert(<Tip.Ok>删除成功!</Tip.Ok>)
            getMsg({is_receiver: 2 - a})
            sSel({})
        })
    }
    return <div className={'msgPage'}>
        <DashTitle title={'站内信息'} cls={'sm'}/>
        <div className={'box-m'} onClick={() => vvm(null)}>
            <Tabs change={aa} index={a}>
                {p => <div className={'t'}>
                    {!!+is_agent && <div {...p(0)}>编写信息</div>}
                    <div {...p(1)}>收件箱</div>
                    <div {...p(2)}>发件箱</div>
                </div>}
            </Tabs>
            {a ? <div className={'rb'}>
                    <div className={'box-opt'}>
                        {panel(!mLd, <label className={'fs'}><i className={'sel' + (isFull ? ' act' : '')}
                                                                   onClick={() => sF(!isFull)}/></label>)}
                        <label>所有：<b>{tm}</b></label>
                        {a === 1 ? <>
                            <label>未读：<b>{un - r}</b></label>
                            <label>已读：<b>{rd + r}</b></label>
                        </> : <></>}
                        <div className={'ss'}/>
                        <label>已选：<b>{sTotal}</b></label>
                        {sTotal ? <>
                            <div className={'del'} onClick={dLd ? null : delMsg}>{dLd ? '删除中' : '删除'}</div>
                            <div className={'clear'} onClick={() => sSel({})}>取消</div>
                        </> : null}
                        <div className={'sel' + (sTotal === msgList.length)} onClick={() => {
                            const al = sTotal === msgList.length;
                            if (al) sSel({});
                            else msgList.forEach(({msg_id}) => selMsg(msg_id, 1))
                        }}/>
                    </div>
                    <div className={'ss so'}>
                        <div className={'sa ss'}>
                            <Table className={'x-table'} data={msgList} row={col} rowCfg={d => {
                                return {
                                    onClick: e => {
                                        e.stopPropagation();
                                        vvm(d)
                                    },
                                    className: (vm && vm.id === d.msg_id) ? 'act' : ''
                                }
                            }}/>
                            <div className={'ss'}/>
                            <Loading act={mLd}>正在加载...</Loading>
                            <Paging data={{totalPage, cPage, handleBtnClick: ccp}}/>
                        </div>
                        {detail}
                    </div>
                </div> :
                <div className={'wr'}>
                    <div className={'re'}>
                        <label>收件人：</label>
                        <div>
                            <Select
                                onChange={tt}
                                value={t}
                                items={[
                                    [0, '直属下级'],
                                    [1, '直属下级代理'],
                                    [2, '直属下级用户'],
                                    [3, '选中联系人']
                                ]}
                            />
                        </div>
                        <div className={'ls'}>
                            {
                                selected.map(([id, name, , sel]) => <span key={id}>{name}<i
                                    onClick={ck(sel, id)}>x</i></span>)
                            }
                        </div>
                    </div>
                    <div className={'ra'}>
                        {t === 3 ? <div className={'rc'}>
                            <div className={'tt'}>
                                <label>选择联系人</label>
                                <input type={'text'} value={f} onChange={({target: {value}}) => {
                                    ff(value)
                                }} placeholder={'搜索'}/>
                            </div>
                            <span className={'s'}>已选联系人<b>（{selected.length}）</b>人</span>
                            <div className={'als'}>
                                <div>
                                    {ft.map(([id, name, ol, sel]) => <R id={id} name={name} online={ol} sel={sel}
                                                                        key={id}/>)}
                                </div>
                                <Loading act={loading}>正在加载联系人列表...</Loading>
                            </div>
                            <span className={'u'}>共<b>{l}</b>位</span>
                        </div> : null}
                        <div className={'rr'}>
                            <div className={'rx'}>
                                <label>主题</label>
                                <input type={'text'} value={subject} onChange={change(ss)}/>
                            </div>
                            <textarea className={'ry'} value={ctx} onChange={change(cc)}/>
                        </div>
                    </div>
                    <div className={'ff'}>
                        <p>贴心小提示：如离开此页面，您填写的内容将被清空</p>
                        <div className={'btn'} onClick={submit}><i/>{sending ? '发送中...' : '发送'}</div>
                    </div>
                </div>}
        </div>
    </div>
});
export const MsgPage = props => <A {...props} cls={'xt'}/>
