import React, {useEffect} from 'react';
import {widthDashBoard} from "../../components/dashboard/Dashboard";
import './i.scss'
import {popSetting} from "../pop/popCenter";
import {history} from "../../store";
import {useQuery} from "../../hooks";

const Box = ({cls, name, desc, finished, t0, t1, onClick}) => {
    return <div className={'bx'}>
        <i className={cls}/>
        <div className={'if'}>
            <label>{name}</label>
            <p>{desc}</p>
        </div>
        <div
            onClick={() => onClick && onClick(finished)}
            className={'bt ' + (finished ? ' f' : '')}>
            {finished ? t1 : t0}
        </div>
    </div>
}

const A = widthDashBoard((props, {
    myBankStore: {banks},
    globalStore: {
        has_fund_password, is_valid_phone
    }
}) => {
    const [query] = useQuery('myBankCard');
    useEffect(() => {
        query()
    }, [])
    const hasBank = banks && banks.length;
    return <div className={'x-sheet cenPage'}>
        <div className={'x-st-h'}>
            <div className="dash-title "><h1>安全中心</h1></div>
            <p className={'tip'}><i/>
                为了确保您的账户安全，请您填写相关安全信息.
            </p>
        </div>
        <div className={'x-ss'}>
            <div className={'x-tba'}>
                <Box cls={'a'}
                     desc={'修改登录时的密码，定期修改有利于账户安全'}
                     name={'登录密码'}
                     onClick={() => popSetting.show(0)}
                     finished={1} t1={'修改'}/>
                <Box cls={'b'}
                     desc={'修改资金密码，定期修改有利于账户安全'}
                     name={'资金密码'}
                     onClick={a => popSetting.show(a ? 2 : 1)}
                     finished={has_fund_password}
                     t0={'设定'}
                     t1={'修改'}/>
                <Box cls={'c'}
                     desc={'绑定银行卡用于取款'}
                     name={'绑定银行卡'}
                     finished={hasBank}
                     onClick={() => history.push('/bank')}
                     t0={'绑定'}
                     t1={'管理'}/>
                <Box cls={'d'}
                     desc={'绑定手机号，防止账户被盗'}
                     name={'验证手机'}
                     finished={is_valid_phone}
                     onClick={a => popSetting.show(a ? 4 : 3)}
                     t0={'验证'}
                     t1={'已验证'}/>
            </div>
        </div>
    </div>
})

export const CenterPage = props => <A {...props} cls={'xt'}/>
