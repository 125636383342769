const lts=[];
export const betStore = {
    issue_lottery:0,
    coldNum: 100, // 冷热期数
    series: {},
    refresh:0,
    loading:0,
    get lotteries() {
        const {allLottery} = this;
        lts.length=0;
        Object.keys(allLottery).forEach(k => lts.push(...allLottery[k]));
        lts.getByCate = (lid) => lts.find(({id}) => +lid === +id);
        lts.getNameById = (lid) =>{
            const g = lts.find(({id}) => +lid === +id);
            if(!g){
                if(!lts.games){
                    const {lottery} = this.getStore('menuStore');
                    const gms = {};
                    lottery.forEach(({children})=>children.forEach(({id,name})=>gms[id]=name));
                    lts.games=gms;
                }
                return lts.games[lid]||''
            }
            return  g.name||''
        }
        lts.getBySeriesId = (lid, sId) => lts.find(({id, series_id}) => +lid === +id && +series_id === +sId);
        return lts;
    },
    allLottery: {},
    allMethods:{},
    changeState: false,
    clickLoading: false,
    cnName: [],
    // UI玩法选择
    way0: 0,
    way1: 0,
    way2: 0,
    get bets() {
        const game = this.playGame;
        if (game && game.getNum) {
            return game.getNum(game.balls, game.position) || 0;
        }
        return 0;
    },
    gKey: null,
    history_gnumber: [],
    current_number: '',
    betInfo: {},
    headerData: [],
    mulriple: 1,//倍数
    max_multiple_old: 0,
    showGameWayPanel: false,
    playName: '',
    playGame: '',
    _submitting: false,
    type: '',//元角分
    isShow: false,
    info: {},
    moneyunit: 1,
    showChange: false,
    countDown: 3,
    time_val: '00:00:00',
    isHistory: false,
    errorMsg: '',
    showErrorPage: false,
    recover(data) {
        const cp = a => JSON.parse(JSON.stringify(a));
        const {balls, position} = data;
        const {playGame} = this;
        if (playGame) {
            Object.assign(playGame, cp({balls, position}));
            const {ways, moneyunit, mulriple, gKey, prizeGroup} = data;
            const [way0, way1, way2] = ways;
            Object.assign(this, {
                way0, way1, way2, moneyunit, mulriple, gKey
            });
            this.getStore('betListStore').prizeGroup = prizeGroup;
        }
    },
};

export const betListStore = {
    curGameId:0,
    traceReturnRate: 50,   // 收益率
    traceMultiple: 1,      // 追号起始倍数
    traceOpMultiple: 2,    // 追号变更倍数
    tractStep: 2,          // 追号间隔
    traceOp: 1,            // 0 - 加 1 - 乘
    traceTimes: 10,        // 追号期数
    traceType: 0,          // 追号类型 0 - 利润率 1 - 同倍 2 - 翻倍
    trRateLis: [],         // 利润率追号
    trSameMultipleLis: [], // 同倍追号
    trMultipleLis: [],     // 翻倍追号
    // 中奖后停止追号
    prizeGroup: 9999, // 选择的奖金组
    traceWinStop: true,
    betList: [],
    betMap: {},
    historyGame: {},
    traceIssues: [],
    traceMax: 1,
    saveCurTrace() {
        const {trRateLis, trSameMultipleLis, trMultipleLis} = this;
        Object.assign(this, {trRateLis, trSameMultipleLis, trMultipleLis});
    },
    clearTrace(type) {
        const {trRateLis, trSameMultipleLis, trMultipleLis} = this;
        if (type === undefined) trRateLis.length = trSameMultipleLis.length = trMultipleLis.length = 0;
        else [trRateLis, trSameMultipleLis, trMultipleLis][type].length = 0;
        Object.assign(this, {trRateLis, trSameMultipleLis, trMultipleLis});
    },
    remove({gKey}) {
        delete this.betMap[gKey];
        const idx = this.betList.findIndex(c => c === gKey);
        if (idx > -1) this.betList.splice(idx, 1);
        Object.assign(this, {betMap: this.betMap, betList: this.betList});
        this.clearTrace();
    },
    clearList() {
        this.betList = [];
        this.betMap = {};
        this.clearTrace();
    },
    addToCart(o) {
        const {betMap, betList} = this;
        const {gKey} = o;
        const has = betMap[gKey];
        betMap[gKey] = Object.assign({}, betMap[gKey] || {}, o);
        this.betMap = betMap;
        if (!has) {
            this.betList = betList.concat(gKey);
        }
        this.clearTrace();
    },
    setTrace(cur) {
        this.traceIssues = cur;
    },
    setTraceMax(n) {
        this.traceMax = n;
    }
};


