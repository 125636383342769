import React from 'react';
import {widthStore} from "../../store";
import {Link} from "react-router-dom";
import {routeMap} from "../../routes/routes";
import {isAgentPage} from "../../utils";


const SL = ({title, desc, c, act}) => {
    return <i className={c + (act ? ' act' : '')}>
        <div>
            {act ? title : desc}
        </div>
    </i>
}

export const BoxMain = widthStore(
    ({globalStore, myBankStore, walletStore}, {match: {path}, children}) => {
        const {user_level, bonus_rate,} = globalStore;
        const m1 = [
            ['i-sc', 'sec'],
            ['i-pz', 'personal'],
            ['i-h3', 'foundHis'],
            ['i-wa', 'bank'],
            ['i-m', 'msg'],
        ];
        const v = [
            ['i-g0', 'agent/analysis'],
            ['i-g1', 'agent/openAccount'],
            ['i-c', 'agent/subManage'],
            ['i-wi', 'agent/teamBet'],
            ['i-h1', 'agent/profit'],
            ['i-h2', 'agent/myInc'],
            ['i-h3', 'agent/foundDetail'],
            ['i-m', 'agent/msg']
        ];
        if (bonus_rate && bonus_rate.length) {
            // v.splice(4, 0, ['i-h2', 'agent/myInc', 0])
            v.splice(2, 0, ['i-th', 'agent/Contracts'])
        }
        const m2 = v.filter(a => a[2] !== user_level)

        let mainPath = window.location.pathname.split('/').slice(1).join('/');
        const buildM = (n, cls, na) => {
            const arr = [].concat(n);
            let hasAct = 0;
            if (na) {
                const [c, hAct] = buildM(arr);
                return [
                    <div key={cls} className={'mu' + (hAct ? ' act' : '')}>
                        <span className={`mu_span`}> <i className={cls}/>{na}</span>
                        <div className={`slide-mu-r`}>{c}</div>
                    </div>,
                    hAct
                ]
            } else {
                return [
                    arr.map(k => {
                        const nb = routeMap.getName(k);
                        const act = mainPath === k || path === k;
                        if (act) hasAct = 1;
                        return <div key={k} className={'mu' + (act ? ' act' : '')}>
                            <Link className={'mu_span'} to={'/' + k}>
                                {cls ? <i className={cls}/> : null}
                                {nb}
                            </Link>
                        </div>
                    }),
                    hasAct
                ];
            }
        };
        return <>
            {isAgentPage() ? null : <div className={'mu-l'}>
                <div className={'mus'}>
                    {
                        m1.map(m => {
                            if (!m) return null;
                            else {
                                const [a, b, c] = m;
                                return buildM(b, a, c)[0]
                            }
                        })
                    }
                </div>
            </div>}
            <div className={'mu-r'}>
                {children}
            </div>
        </>
    });
