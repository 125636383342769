export const _115sanmazhixuanfushi = {
	balls:[
	    [-1,-1,-1,-1,-1,-1,-1,-1,-1,-1,-1],
        [-1,-1,-1,-1,-1,-1,-1,-1,-1,-1,-1],
        [-1,-1,-1,-1,-1,-1,-1,-1,-1,-1,-1]
	],
    num:[
	    ['01', '02', '03', '04', '05', '06', '07', '08', '09', '10', '11'],
        ['01', '02', '03', '04', '05', '06', '07', '08', '09', '10', '11'],
        ['01', '02', '03', '04', '05', '06', '07', '08', '09', '10', '11']
    ],
    views:[
	    ['01', '02', '03', '04', '05', '06', '07', '08', '09', '10', '11'],
        ['01', '02', '03', '04', '05', '06', '07', '08', '09', '10', '11'],
        ['01', '02', '03', '04', '05', '06', '07', '08', '09', '10', '11']
    ],
    chooseType:['全','大','小','奇','偶','清'],
    titles: [
	    '一位',
        '二位',
        '三位'
    ],
    getLottery:(num,balls)=>{
        let str = '',str_ = ''; 
        for(let i = 0,len = balls.length;i < len;i++){ 
            str += i==0?'':'|';
            str_ = '';
		    for(let l = 0,len_l = balls[i].length;l < len_l;l++){    
			    if(balls[i][l] > 0){
				    str_ += str_?(' '+num[i][l]):num[i][l];
				}   
		    }
            str += str_;
    	}
	    return str;
    },
    getNum:(balls)=>{
        let num = 0;
        if(balls.length){
            for(let i=0,len_i=balls[0].length;i<len_i;i++){
                if(balls[0][i]>0){
                    for(let j=0,len_j = balls[1].length;j<len_j;j++){
                        if(balls[1][j]>0 && j!=i){
                            for(let k=0,len_k = balls[2].length;k<len_k;k++){
                                if(balls[2][k]>0 && k!=j && k!=i){
                                    num++;
                                }
                            }
                        }
                    }
                }
            }
        }
        return num;
    },
    getView: (views,balls)=>{
        let str = '',str_ = ''; 
        for(let i = 0,len = balls.length;i < len;i++){ 
            str += i==0?'':'|';
            str_ = '';
            for(let l = 0,len_l = balls[i].length;l < len_l;l++){    
                if(balls[i][l] > 0){
                    str_ += str_?(' '+views[i][l]):views[i][l];
                }   
            }
            str += str_;
        }
        return str;
    }
}
